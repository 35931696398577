






















































































































































































import { defineComponent, computed } from '@vue/composition-api';
import { Scrollbar, SvgImage } from '@/app/components';
import { ErrorCodes } from '@/app/constants/error-codes';
import CleaningFieldView from './CleaningFieldView.vue';
import WizardActions from '../../components/WizardActions.vue';
import {renamings} from '@/app/utilities';
export default defineComponent({
    name: 'CleaningReview',
    components: { Scrollbar, CleaningFieldView, WizardActions, SvgImage },
    props: {
        configuration: {
            type: Object,
            required: true,
        },
        isFinalized: {
            type: Boolean,
            default: false,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        hasChanges: {
            type: Boolean,
            required: true,
        },
        message: {
            type: Object,
            required: false,
        },
        stats: {
            type: Object,
            required: false,
        },
        canRestart: {
            type: Boolean,
            default: false,
        },
        hasDifference: {
            type: Boolean,
            default: true,
        },
    },
    setup(props) {
        const fieldsWithConstraints = computed(() => {
            return props.configuration.fields.filter((obj: any) => {
                return obj.constraints.length > 0;
            });
        });

        const fieldsWithNoConstraints = computed(() => {
            const concepts = props.configuration.fields.filter((obj: any) => {
                return obj.constraints.length === 0;
            });
            const splitConcepts: any[] = [];
            let currentArray: any[] = [];
            concepts.forEach((concept: any) => {
                if (currentArray.length === 4) {
                    splitConcepts.push(currentArray);
                    currentArray = [];
                }
                currentArray.push(concept);
            });
            if (currentArray.length > 0) {
                splitConcepts.push(currentArray);
            }

            return splitConcepts;
        });

        const statsInField = (field: any) => {
            if (props.stats) {
                return Object.keys(props.stats)
                    .filter((key) => field.constraints.map((constraint: any) => constraint.id).includes(Number(key)))
                    .reduce((obj, key) => {
                        if (props.stats) return { ...obj, [key]: props.stats[key] };
                        return obj;
                    }, {});
            }
            return {};
        };

        const failedStatsInField = (field: any) => {
            if (props.message && props.message.stats) {
                return Object.keys(props.message.stats)
                    .filter((key) => field.constraints.map((constraint: any) => constraint.id).includes(Number(key)))
                    .reduce((obj, key) => {
                        if (props.message && props.message.stats) return { ...obj, [key]: props.message.stats[key] };
                        return obj;
                    }, {});
            }
            return {};
        };

        const failedConstraintsInField = (field: any) => {
            if (props.message && props.message.failedConstraints) {
                return Object.keys(props.message.failedConstraints)
                    .filter((key: any) =>
                        field.constraints.map((constraint: any) => constraint.id).includes(Number(key)),
                    )
                    .reduce((obj: any, key: any) => {
                        if (props.message && props.message.failedConstraints)
                            return { ...obj, [key]: props.message.failedConstraints[key] };
                        return obj;
                    }, {});
            }
            return [];
        };

        const errorMessage = computed(() =>
            props.message
                ? ErrorCodes[props.message.errorCode] || `Unknown Error Code: ${props.message.errorCode}`
                : `Unknown Error`,
        );

        return {
            statsInField,
            failedStatsInField,
            failedConstraintsInField,
            fieldsWithConstraints,
            fieldsWithNoConstraints,
            errorMessage,
            renamings
        };
    },
});
