import * as R from 'ramda';
import { parseString, processors } from 'xml2js';

export function useHarvester(root: any, emit: any) {
    const arraySizeLimit = 25;

    /**
     * Sets sample/ processed sample
     * @param sample sample to be processed or uploaded
     * @param source configuration source
     */
    const changeFinalSample = (sample: any, source: string) => {
        if (R.type(sample) === 'Object') {
            emit(source === 'externalKafka' ? 'process-sample' : 'sample-uploaded', [sample]);
        } else {
            emit(source === 'externalKafka' ? 'process-sample' : 'sample-uploaded', sample);
        }
    };

    /**
     * Crops response object/ array to specific size
     * @param response response object/ array to be cropped
     * @param size crop response object/ array to this size (defaulted to 10)
     */
    const limitResponse = (response: any, size = 10) => {
        if (R.type(response) === 'Array') {
            return response.slice(0, size);
        }

        if (R.type(response) === 'Object') {
            return Object.keys(response).reduce((result: any, key: string) => {
                Reflect.set(result, key, limitResponse(response[key], size));
                return result;
            }, {});
        }

        return response;
    };

    /**
     * Crops array of values and array of objects down to a specific limit (determined by arraySizeLimit)
     * @param obj object to be cropped
     */
    const reduceSampleValues = (obj: any) => {
        let cropped = false;
        if (typeof obj === 'object') {
            Object.keys(obj).forEach((k) => {
                if (typeof obj[k] === 'object' && obj[k] !== null) {
                    if (obj[k] instanceof Array && obj[k].length > arraySizeLimit) {
                        let isArray = false; // if at least 1 of array's values is array then it means there are more nested levels
                        for (let i = 0; i < obj[k].length; i++) {
                            if (obj[k][i] instanceof Array && obj[k][i] !== null) {
                                isArray = true;
                            }
                        }

                        if (!isArray) {
                            obj[k].splice(arraySizeLimit);
                            cropped = true;
                        }
                    }
                    reduceSampleValues(obj[k]);
                }
            });
        }
        if (cropped) {
            emit('sample-cropped', cropped);
        }
        return obj;
    };

    /**
     * Checks if json is empty
     * @param json json under validation
     */
    const checkEmptyJSON = (json: any): boolean => {
        if (R.isNil(json) || R.isEmpty(json)) {
            return true;
        }
        if (R.type(json) === 'Array') {
            return checkEmptyJSON(json[0]);
        }
        return false;
    };

    /**
     * Checks whether a file is of valid JSON format, crops the file data to a specific size if required and checks if non empty
     * @param file file under validation
     */
    const parseJSON = async (file: any) => {
        if (!file) return;
        const data = await file.text();
        let json = null;
        try {
            json = JSON.parse(data);
            json = reduceSampleValues(json);
        } catch (e) {
            (root as any).$toastr.e('Invalid JSON format!', 'Error');
            emit('sample-uploaded', null);
            emit('files-changed', { sample: null });
            emit('parsed-sample-uploaded', null);
            return;
        }
        if (checkEmptyJSON(json)) {
            (root as any).$toastr.e('Empty JSON file!', 'Error');
            emit('sample-uploaded', null);
            emit('files-changed', { sample: null });
            emit('parsed-sample-uploaded', null);
            return;
        }
        if (R.type(json) === 'Object') {
            emit('sample-uploaded', [json]);
        } else {
            emit('sample-uploaded', json);
        }
        emit('parsed-sample-uploaded', json);
    };

    /**
     * Checks whether a file is of valid XML format
     * @param file file under validation
     */
    const checkInvalidXML = async (file: any) => {
        const data = await file.text();
        let invalidFormat = false;
        parseString(data, (err, result) => {
            if (err || !result) {
                invalidFormat = true;
            } else {
                invalidFormat = false;
            }
        });
        return invalidFormat;
    };

    /**
     * Parses an XML file and crops its data to a specific size if required
     * @param file file under parsing
     */
    const parseXML = async (file: any) => {
        if (!file) return;
        const addAt = (attrName: string) => {
            return `@${attrName}`;
        };
        const data = await file.text();
        parseString(
            data,
            {
                attrkey: '@',
                charkey: '$',
                explicitCharkey: false,
                trim: true,
                emptyTag: {},
                explicitArray: false,
                mergeAttrs: true,
                attrNameProcessors: [addAt],
                attrValueProcessors: [processors.parseNumbers, processors.parseBooleans],
                valueProcessors: [processors.parseNumbers, processors.parseBooleans],
            },
            (err, result) => {
                if (!err) {
                    const parsedSample = reduceSampleValues(result);
                    if (R.type(result) === 'Object') {
                        emit('sample-uploaded', [parsedSample]);
                    } else {
                        emit('sample-uploaded', parsedSample);
                    }
                    emit('parsed-sample-uploaded', parsedSample);
                }
            },
        );
    };

    const findArrayPath = (obj: any, key: string, separator = '||'): string[] | null => {
        if (R.is(Array, obj)) {
            return [key];
        }
        if (R.is(Object, obj)) {
            const keys = Object.keys(obj);
            if (keys.length > 0) {
                let subArrays: string[] = [];
                for (let k = 0; k < keys.length; k++) {
                    const subKey = keys[k];
                    const subArray = findArrayPath(obj[subKey], `${key}${separator}${subKey}`);
                    if (subArray) {
                        subArrays = [...subArrays, ...subArray];
                    }
                }
                return subArrays;
            }
        }
        return null;
    };

    const clearFiles = () => {
        emit('files-changed', { sample: null });
    };

    return {
        changeFinalSample,
        limitResponse,
        parseJSON,
        checkInvalidXML,
        parseXML,
        findArrayPath,
        clearFiles,
        reduceSampleValues,
        checkEmptyJSON,
    };
}
