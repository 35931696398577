










































































































import * as R from 'ramda';
import { computed, defineComponent, ref, watch } from '@vue/composition-api';
import { useHarvester } from '../composable';
import { FormBlock } from '@/app/components';

export default defineComponent({
    name: 'ResponseHandling',
    components: {
        FormBlock,
    },
    props: {
        configuration: {
            type: Object,
            required: true,
        },
        jobConfig: {
            type: Object,
        },
        basePath: {
            type: String,
            required: true,
        },
        completed: {
            type: Boolean,
            default: true,
        },
        sample: {
            type: Array,
            required: false,
        },
        parsedSample: {
            type: [Object, Array],
            required: true,
        },
        separator: {
            type: String,
            default: '||',
        },
    },
    setup(props, { emit, root }) {
        const { findArrayPath } = useHarvester(root, emit);
        const isSampleArray = computed(() => R.is(Array, props.parsedSample));
        const focusChoice = ref<string>(props.jobConfig && props.jobConfig.multiple ? 'specificPath' : 'wholeObject');
        const focusSelectedNestedArrayBasePath = ref<string | null>(null);
        const objectData = computed(() => (R.is(Object, props.parsedSample) ? props.parsedSample : {}));

        /**
         * Calculates if there are any inner arrays, in order to give the user the option to select a different base path other than the root.
         */
        const focusNestedArrays = computed(() => {
            let arrays: string[] = [];
            if (objectData.value && !isSampleArray.value) {
                Object.keys(objectData.value).forEach((key: string) => {
                    const objArrays: string[] | null = findArrayPath(objectData.value[key], key);
                    if (objArrays) {
                        arrays = [...arrays, ...objArrays];
                    }
                });
            }
            return arrays;
        });

        /**
         * Calculates to true in case the user has selected a different base path other than the root but has also selected nodes outside
         * this base path so that we show to the user a warning how these node selections will be handled
         */
        const focusSelectedObjectsFromOutside = computed(() => {
            if (
                props.jobConfig?.basePath &&
                focusChoice.value === 'specificPath' &&
                focusSelectedNestedArrayBasePath.value
            ) {
                for (let i = 0; i < props.configuration.response.selectedItems.length; i++) {
                    const arrayPath = props.configuration.response.selectedItems[i];
                    if (!arrayPath.startsWith(props.jobConfig.basePath)) {
                        return true;
                    }
                }
            }
            return false;
        });

        const jobConfig = computed(() => {
            if (objectData.value && props.basePath) {
                let selectedItems = [];
                let { basePath }: { basePath: string | null } = props;
                let multiple = false;

                if (props.configuration?.response?.selectedItems) {
                    selectedItems = props.configuration.response.selectedItems;
                }

                if (isSampleArray.value) {
                    multiple = true;
                } else if (focusChoice.value === 'specificPath') {
                    basePath = `${props.basePath}${props.separator}${focusSelectedNestedArrayBasePath.value}`;
                    multiple = true;
                }

                return {
                    basePath,
                    multiple,
                    selectedItems,
                };
            }

            return null;
        });

        watch(
            () => jobConfig.value,
            (config: any) => {
                if (!props.completed) {
                    emit('job-config-change', config);
                }
            },
        );

        watch(
            () => focusChoice.value,
            (choice: string) => {
                if (
                    choice === 'specificPath' &&
                    focusNestedArrays.value.length > 0 &&
                    !focusSelectedNestedArrayBasePath.value
                ) {
                    // eslint-disable-next-line prefer-destructuring
                    focusSelectedNestedArrayBasePath.value = focusNestedArrays.value[0];
                }
            },
        );

        if (
            !isSampleArray.value &&
            props.jobConfig &&
            props.jobConfig.basePath &&
            focusNestedArrays.value.includes(
                props.jobConfig.basePath.replace(`${props.basePath}${props.separator}`, ''),
            )
        ) {
            focusSelectedNestedArrayBasePath.value = props.jobConfig.basePath.replace(
                `${props.basePath}}${props.separator}`,
                '',
            );
        } else if (focusNestedArrays.value.length > 0) {
            // eslint-disable-next-line prefer-destructuring
            focusSelectedNestedArrayBasePath.value = focusNestedArrays.value[0];
        }

        return {
            isSampleArray,
            focusNestedArrays,
            focusSelectedObjectsFromOutside,
            focusChoice,
            focusSelectedNestedArrayBasePath,
        };
    },
});
