





































































































































































































































































































































































































































































import * as R from 'ramda';
import { defineComponent, ref, computed, watch } from '@vue/composition-api';
import { ValidationProvider, extend, ValidationObserver } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import Papa from 'papaparse';
import { parseString, processors } from 'xml2js';
import { useQuery, useResult, useFilters, useErrors } from '@/app/composable';
import { FormBlock, FileDropzone, TwButton, JsonParser, SvgImage, TwProgressBar } from '@/app/components';
import GET_JOB_WITH_STEPS from '../../graphql/getJobWithEnabledSteps.graphql';
import { useHarvester } from '../../composable';

extend('required', required);

extend('filepath', {
    message: (field, args) => {
        const [filetype] = args ? args[0].split(',') : '';
        return `The specified ${field} is not a valid path\n Sample path for linux: /home/my_data/my_file${filetype}\n Sample path for Windows: C:\\Documents\\my_data\\my_file${filetype}`;
    },
    validate: (value, args) => {
        let filetype: string = args[0];
        filetype = filetype.split(',').join('|');
        const winPath = new RegExp(`([a-zA-Z]:)?(\\\\[a-z  A-Z0-9_.-]+)+\\\\?(${filetype})$`);

        const linuxPath = new RegExp(`^(/[^/]*)+/?(${filetype})$`);

        return winPath.test(value) || linuxPath.test(value);
    },
});

export interface FilesData {
    sample: File | null;
    data: File[] | null;
}

export default defineComponent({
    name: 'FilesConfiguration',
    model: {
        prop: 'configuration',
    },
    components: {
        FormBlock,
        ValidationProvider,
        FileDropzone,
        TwButton,
        ValidationObserver,
        JsonParser,
        SvgImage,
        TwProgressBar,
    },
    props: {
        jobId: {
            type: Number,
            required: true,
        },
        configuration: {
            type: Object,
            required: true,
        },
        sample: {
            type: Array,
            required: false,
        },
        files: {
            type: Object,
            required: true,
        },
        activeTab: {
            type: Number,
            required: true,
        },
        completed: {
            type: Boolean,
            default: true,
        },
        canUploadMore: {
            type: Boolean,
            default: false,
        },
        isJobCompleted: {
            type: Boolean,
            default: false,
        },
        isOnPremise: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { emit, root }) {
        const { formatBytes } = useFilters();
        const filesValidationRef = ref<any>(null);
        const sampleRef = ref<any>(null);
        const fileRef = ref<any>(null);
        const inputFilename = ref<any>(null);
        const dropzoneRef = ref<any>(null);
        const invalidFormat = ref<boolean>(false);
        const duplicateHeaders = ref<boolean>(false);
        const necessaryFields = ref<any>(null);
        const extraInvalidFields = ref<any>(null);
        const loading = ref<any>(false);
        const showValidationBar = ref<boolean>(false);
        const validating = ref<any>(null);
        const valPercentage = ref<number>(0);

        const errorAlert: any = ref({
            title: null,
            body: {
                necessary: null,
                invalid: null,
            },
        });

        const sampleFile = computed(() => props.files.sample);
        const uploadFile = computed(() => props.files.data?.[0]);
        const disableFileTypeChange = computed(
            () => props.completed || (props.configuration.files && props.configuration.files.length > 0),
        );
        const disableBrowseSample = computed(
            () =>
                props.completed ||
                (props.configuration.files && props.configuration.files.length > 0) ||
                (!sampleFile.value && props.sample),
        );

        const { parseJSON, checkInvalidXML, parseXML, reduceSampleValues } = useHarvester(root, emit);

        const { checkGQLAuthentication } = useErrors(root.$route);
        const { result: jobResult, onError } = useQuery(
            GET_JOB_WITH_STEPS,
            { id: props.jobId },
            { fetchPolicy: 'no-cache' },
        );
        onError(checkGQLAuthentication);
        const job = useResult(jobResult, null, (data: any) => data.job);
        const mappingStepExists = computed(() => {
            if (
                job.value &&
                job.value.dataCheckinJobSteps.find((step: any) => step.dataCheckinStepType.name === 'mapping')
            ) {
                return true;
            }
            return false;
        });

        const fileBlockInfo = computed(() => {
            if (props.isJobCompleted && !props.isOnPremise) {
                return {
                    title: 'Upload Additional File(s)',
                    description: 'Upload additional file(s) to be processed (if in csv, json, xml format).',
                };
            }
            if (props.isOnPremise)
                return {
                    title: 'File(s) path',
                    description: 'Provide your file(s) paths to be processed (if in csv, json, xml format)',
                };
            return {
                title: 'Upload File(s)',
                description: 'Upload your file(s) to be processed (if in csv, json format)',
            };
        });
        const imageBasedOnFiletype = computed(() =>
            props.configuration.fileType === 'other' ? '/img/files_uploaded.svg' : '/img/no_data.svg',
        );

        const messageBasedOnFiletype = computed(() =>
            props.configuration.fileType === 'other' ? 'Uploaded successfully' : 'No data sample uploaded',
        );

        const acceptedFiles = computed(() => {
            switch (props.configuration.fileType) {
                case 'csv':
                    return '.csv,.tsv';
                case 'json':
                    return '.json';
                case 'xml':
                    return '.xml';
                default:
                    return '.*';
            }
        });

        const clearErrorAlert = () => {
            errorAlert.value = {
                title: null,
                body: {
                    necessary: null,
                    invalid: null,
                },
            };
        };

        const validateAndProceed = async () => {
            if (filesValidationRef.value) {
                const valid = await filesValidationRef.value.validate();
                if (valid) {
                    if (props.configuration.isSampleCropped && errorAlert.value.title) {
                        await clearErrorAlert();
                    }
                    emit('next-tab');
                }
            }
        };

        const validate = async () => {
            return filesValidationRef.value.validate();
        };

        const clearFiles = () => {
            emit('files-changed', { sample: null, data: null });
            props.configuration.params = {}; // eslint-disable-line no-param-reassign
            clearErrorAlert();
        };

        /**
         * Parses a CSV file.
         * If validation is set to true, it checks for duplicate headers or errors. If any, it stops and an error message is appeared.
         * @param file CSV file
         * @param validation Whether or not to validate the CSV data
         * @param records Return a specific number of records from the CSV data
         */
        const parseCSV = (file: any, validation = true, records: number | null = null) => {
            const CHUNK_SIZE = 1024 * 1024 * 5; // 5MB
            const totalChunks = Math.ceil(file.size / CHUNK_SIZE);
            if (totalChunks > 50) showValidationBar.value = true; // show validation progress bar only on large files
            let chunk = 0;

            return new Promise<any>((resolve) => {
                Papa.parse(file, {
                    header: true,
                    preview: records || null,
                    chunkSize: !records ? CHUNK_SIZE : null,
                    skipEmptyLines: true,
                    dynamicTyping: !!records,
                    transform: (value: any) => (records ? value.trim() : value),
                    chunk: (results: any) => {
                        chunk += 1;
                        if (records) resolve(results);
                        if (validation) {
                            valPercentage.value = Math.ceil((chunk / totalChunks) * 100);

                            // check if there are any duplicate headers
                            const headers = results.meta.fields;
                            const uniqueHeaders = new Set(headers); // keep only the unique headers

                            duplicateHeaders.value = headers.length !== uniqueHeaders.size;
                            invalidFormat.value = !!results.errors.length; // check if there are any errors, thus invalid file format
                            if (invalidFormat.value || duplicateHeaders.value || chunk === totalChunks) {
                                resolve(null);
                            }
                        }
                    },
                } as any);
            });
        };

        const getSampleCSV = async (file: any) => {
            if (!file) return;
            const results = await parseCSV(file, false, 50);
            props.configuration.params = R.pick(['delimiter', 'linebreak', 'fields'], results.meta); // eslint-disable-line no-param-reassign
            emit('sample-uploaded', results.data);
        };

        const checkInvalidCSV = async (file: any) => {
            if (!file) return;
            await emit('set-loading', true);
            loading.value = true;
            await parseCSV(file);
            await emit('set-loading', false);
            loading.value = false;
            validating.value = null;
            showValidationBar.value = false;
            valPercentage.value = 0;
        };

        const checkInvalidJSON = async (file: any) => {
            if (!file) return;
            const data = await file.text();
            try {
                JSON.parse(data);
                invalidFormat.value = false;
            } catch (e) {
                invalidFormat.value = true;
            }
        };

        const getAllPaths = (obj: any, prefix: string, key: string): string[] => {
            if (obj instanceof Array) {
                const allPaths = [];
                if (key !== '') {
                    allPaths.push(prefix + key);
                }
                let newPrefix = prefix;
                if (prefix[prefix.length - 1] !== '||') {
                    newPrefix = `${prefix}${'||'}`;
                }
                if (newPrefix === '||') {
                    newPrefix = '';
                }
                let p: any = [];
                for (let k = 0; k < obj.length; k += 1) {
                    p = p.concat(getAllPaths(obj[k], newPrefix, `${key}`));
                }
                for (let j = 0; j < p.length; j += 1) {
                    allPaths.push(p[j]);
                }

                return [...new Set(allPaths)];
            }
            if (obj instanceof Object) {
                const keys = Object.keys(obj);
                const allPaths = [];
                if (key !== '') {
                    allPaths.push(prefix + key);
                }
                for (let i = 0; i < keys.length; i += 1) {
                    let newPrefix = `${prefix + key}${'||'}`;
                    if (newPrefix === '||') {
                        newPrefix = '';
                    }
                    const p = getAllPaths(obj[keys[i]], newPrefix, keys[i]);
                    for (let j = 0; j < p.length; j += 1) {
                        allPaths.push(p[j]);
                    }
                }

                return [...new Set(allPaths)];
            }

            if (R.isEmpty(key)) return [];
            return [prefix + key];
        };

        /**
         * Compares the csv headers (columns) of the sample file with the csv headers of the full file to be uploaded.
         * If a header/ column in either csv file (sample or full file) is empty, then in the error displayed to the user,
         * it is written as 'empty column header'
         * @param data CSV text data to be parsed
         */
        const calculateInconsistenciesCSV = async (file: any) => {
            const results = await parseCSV(file, false, 10);
            const headers = results.meta.fields;
            if (props.sample?.length) {
                const sampleHeaders = props.configuration.params.fields;
                necessaryFields.value = sampleHeaders.filter((h: any) => headers.indexOf(h) === -1);
                necessaryFields.value.forEach((w: any) => {
                    if (!w) {
                        const idx = necessaryFields.value.indexOf(w);
                        necessaryFields.value[idx] = 'empty column header';
                    }
                });

                extraInvalidFields.value = headers.filter((h: any) => sampleHeaders.indexOf(h) === -1);
                extraInvalidFields.value.forEach((w: any) => {
                    if (!w) {
                        const idx = extraInvalidFields.value.indexOf(w);
                        extraInvalidFields.value[idx] = 'empty column header';
                    }
                });

                // duplicate headers of file are considered as extra invalid fields
                const duplicateHeadersFile = headers.filter(
                    (header: any, idx: number) => headers.indexOf(header) !== idx,
                );
                if (duplicateHeadersFile.length) {
                    extraInvalidFields.value = extraInvalidFields.value.concat(duplicateHeadersFile);
                }
            }
        };

        const calculateFilteredPaths = (paths: any) => {
            const filteredPaths: any = [];
            paths.forEach((p: any) => {
                let pathWithoutSeparator = p.split('||');
                pathWithoutSeparator = pathWithoutSeparator.filter((v: any) => v !== '');
                if (!filteredPaths.find((fp: any) => fp.toString() === pathWithoutSeparator.toString())) {
                    filteredPaths.push(pathWithoutSeparator);
                }
            });
            return filteredPaths;
        };

        /**
         * Compares the structure of the uploaded sample with the full file to be uploaded
         * @param file Full file to be uploaded
         * @param fileType Format of full file to be uploaded (json, xml, csv)
         */
        const compareSampleAndFile = async (file: any, fileType: string) => {
            let data = null;

            // extract/ parse the text data from the file
            if (fileType === 'xml') {
                // parse XML
                const addAt = (attrName: string) => {
                    return `@${attrName}`;
                };
                const xmlData = await file.text();
                parseString(
                    xmlData,
                    {
                        attrkey: '@',
                        charkey: '$',
                        explicitCharkey: false,
                        trim: true,
                        emptyTag: {},
                        explicitArray: false,
                        mergeAttrs: true,
                        attrNameProcessors: [addAt],
                        attrValueProcessors: [processors.parseNumbers, processors.parseBooleans],
                        valueProcessors: [processors.parseNumbers, processors.parseBooleans],
                    },
                    (err, result) => {
                        if (!err) {
                            if (R.type(result) === 'Object') {
                                data = [result];
                            } else {
                                data = result;
                            }
                        }
                    },
                );
            } else {
                data = await file.text();
            }

            if (fileType === 'csv') {
                await calculateInconsistenciesCSV(file);
            }

            if (fileType === 'json' || fileType === 'xml') {
                let fileUpload: any = fileType === 'json' ? JSON.parse(data) : data;
                if (props.configuration.isSampleCropped) {
                    fileUpload = await reduceSampleValues(fileUpload);
                }

                // remove any paths which are identical
                const paths: any = [...new Set(await getAllPaths(props.sample, '', ''))];
                const invalidPaths: any = [...new Set(await getAllPaths(fileUpload, '', ''))];

                necessaryFields.value = [];
                extraInvalidFields.value = [];

                const filteredPaths = await calculateFilteredPaths(paths);
                const filteredInvalidPaths = await calculateFilteredPaths(invalidPaths);

                // calculate which fields exist in the sample, but not in the full file to be uploaded
                filteredPaths.forEach((fp: any) => {
                    if (!filteredInvalidPaths.find((ip: any) => ip.toString() === fp.toString())) {
                        necessaryFields.value.push(fp);
                    }
                });

                // calculate which fields exist in the full file to be uploaded, but not in the sample
                filteredInvalidPaths.forEach((fp: any) => {
                    if (!filteredPaths.find((ip: any) => ip.toString() === fp.toString())) {
                        extraInvalidFields.value.push(fp);
                    }
                });

                // remove any paths which are identical
                necessaryFields.value = [...new Set(necessaryFields.value)];
                extraInvalidFields.value = [...new Set(extraInvalidFields.value)];
            }

            if (
                (necessaryFields.value && necessaryFields.value.length) ||
                (extraInvalidFields.value && extraInvalidFields.value.length)
            ) {
                invalidFormat.value = true;
                errorAlert.value.title = `Inconsistencies detected between the Sample and the File "${file.name}"`;
                (root as any).$toastr.e('File and Sample do not have the same structure!', 'Error');
            } else if (props.configuration.isSampleCropped) {
                invalidFormat.value = false;
                errorAlert.value.title = `Inconsistencies may appear between the Sample and the File "${file.name}", because the Sample has been cropped.`;
            } else {
                invalidFormat.value = false;
                errorAlert.value.title = null;
                errorAlert.value.body = {
                    necessary: null,
                    invalid: null,
                };
            }
        };

        const filesAdded = (data: File[]) => {
            emit('files-changed', { data });
        };

        const filetypeValidation = async (filetype: string, files: any) => {
            if (invalidFormat.value) {
                await emit('files-changed', { data: null });
                if (!errorAlert.value.title) {
                    (root as any).$toastr.e(`Invalid ${filetype} format!`, 'Error');
                }
            } else {
                await emit('files-changed', { data: files });
            }
        };

        const fileUploaded = async (event: any) => {
            const { files } = event.target;
            const uploadedFile = files[0];
            const filename = uploadedFile.name;
            clearErrorAlert();

            if (!props.configuration.files.includes(filename)) {
                await emit('set-loading', true);
                loading.value = true;
                if (props.configuration.fileType === 'json') {
                    await checkInvalidJSON(uploadedFile);

                    if (!invalidFormat.value) {
                        await compareSampleAndFile(R.clone(uploadedFile), 'json');
                    }
                    await filetypeValidation('JSON', files);
                } else if (props.configuration.fileType === 'xml') {
                    invalidFormat.value = await checkInvalidXML(R.clone(uploadedFile));

                    if (!invalidFormat.value) {
                        await compareSampleAndFile(uploadedFile, 'xml');
                    }
                    await filetypeValidation('XML', files);
                } else if (props.configuration.fileType === 'csv') {
                    validating.value = 'file';
                    emit('files-changed', { data: null });
                    await checkInvalidCSV(uploadedFile);
                    if (!invalidFormat.value) {
                        await compareSampleAndFile(uploadedFile, 'csv');
                    }
                    await filetypeValidation('csv', files);
                } else {
                    await emit('files-changed', { data: files });
                }
            } else {
                (root as any).$toastr.e(`File with filname "${filename} already uploaded!`, 'Error');
                await emit('files-changed', { data: null });
            }
            await validate();
            await emit('set-loading', false);
            loading.value = false;
        };

        const sampleUploaded = async (event: any) => {
            const file = event.target.files[0];
            props.configuration.params = {}; // eslint-disable-line no-param-reassign
            await emit('sample-cropped', false);
            await clearFiles();

            switch (props.configuration.fileType) {
                case 'csv':
                    validating.value = 'sample';
                    emit('files-changed', { sample: null });
                    await checkInvalidCSV(file);
                    if (invalidFormat.value || duplicateHeaders.value) {
                        (root as any).$toastr.e(
                            invalidFormat.value
                                ? 'Invalid CSV format!'
                                : 'Duplicate columns have been detected in the file!',
                            'Error',
                        );

                        errorAlert.value.title = duplicateHeaders.value
                            ? `Duplicate columns have been detected in the Sample file "${file.name}"`
                            : null;
                    } else {
                        await emit('files-changed', { sample: file });
                        await getSampleCSV(file);
                        clearErrorAlert();
                    }
                    break;
                case 'json':
                    await emit('files-changed', { sample: file });
                    await parseJSON(file);
                    break;
                case 'xml':
                    invalidFormat.value = await checkInvalidXML(file);
                    if (invalidFormat.value) {
                        emit('files-changed', { sample: null });
                        (root as any).$toastr.e('Invalid XML format!', 'Error');
                    } else {
                        await emit('files-changed', { sample: file });
                        await parseXML(file);
                    }
                    break;
                default:
                // Do nothing
            }
            await validate();
        };

        watch(dropzoneRef, () => {
            if (dropzoneRef.value && props.files.data) {
                const files = R.clone(props.files.data);
                files.forEach((file: File) => {
                    dropzoneRef.value.addFile(file);
                });
            }
        });

        const sampleMayBeCroppedMessage = computed(() =>
            props.configuration.fileType && props.configuration.fileType !== 'csv'
                ? '- Sample may be cropped if required. Ensure that a small sample contains all necessary fields.'
                : '',
        );

        const sampleCroppedMessage = computed(() => (props.configuration.isSampleCropped ? 'cropped, ' : ''));

        return {
            fileBlockInfo,
            acceptedFiles,
            clearFiles,
            disableFileTypeChange,
            dropzoneRef,
            fileRef,
            filesAdded,
            fileUploaded,
            filesValidationRef,
            formatBytes,
            sampleFile,
            sampleRef,
            sampleUploaded,
            uploadFile,
            validate,
            validateAndProceed,
            mappingStepExists,
            errorAlert,
            necessaryFields,
            extraInvalidFields,
            disableBrowseSample,
            imageBasedOnFiletype,
            messageBasedOnFiletype,
            loading,
            sampleMayBeCroppedMessage,
            sampleCroppedMessage,
            inputFilename,
            valPercentage,
            validating,
            showValidationBar,
        };
    },
});
