import { Ref, computed, ref } from '@vue/composition-api';
import { useAxios } from '@vue-composable/axios';
import { omit, isEmpty, clone, pluck } from 'ramda';
import { DataCheckinJob, DataCheckinJobStep } from '../types';
import { StatusCode } from '../constants';
import { StatusCode as AssetStatusCodes } from '../../asset/constants';
import { JobsAPI, ModelAPI } from '../api';
import { AssetsAPI } from '../../asset/api';
import { useJobConfiguration } from './config-extraction';
import { useMapping } from './mapping';

export interface UseStepsConfig {
    jobId: number;
    stepName: string;
}

export function useStep(step: Ref<DataCheckinJobStep | null>, job: Ref<DataCheckinJob | null>) {
    const { getStructure, getLoaderSchema } = useJobConfiguration(job);
    const { extractMappingFieldNames } = useMapping([], null);
    const allowStepRestart = ref<boolean>(false);

    /**
     * Checks if a step has been finalized
     */
    const isFinalized = computed<boolean>(
        () => !!step.value && step.value.status !== StatusCode.Configuration && step.value.status !== StatusCode.Update,
    );

    /**
     * allow step restart only if the step has failed, it has never been
     * executed successfully and if file or recurring api/ kafka/ externalKafka type of job
     */
    const canRestart = computed(
        () => step.value && step.value.status === StatusCode.Failed && !step.value.stats && allowStepRestart.value,
    );

    /**
     * Check if a step can be restarted based on if it's file or recurring api/ kafka/ externalKafka DCJs
     */
    const checkStepRestartEligibility = () => {
        allowStepRestart.value = false;
        if (step.value) {
            const { exec } = useAxios();
            exec(JobsAPI.getStep(step.value.dataCheckinJobId, 'harvester')).then(async (res: any) => {
                const harvesterConfig = res.data.configuration;
                allowStepRestart.value =
                    ['file', 'kafka', 'externalKafka', 'mqtt'].includes(harvesterConfig.source) ||
                    (harvesterConfig.source === 'api' && harvesterConfig.retrieval.type !== 'once');
            });
        }
    };

    /**
     * Checks if a configuration is empty. We consider a configuration empty if the only properties
     * it has are 'files' and/or 'scheduler'
     */
    const isConfigEmpty = (configuration: any): boolean =>
        configuration === null || isEmpty(omit(['files', 'scheduler'], configuration));

    const getNextStep = (): any => {
        return new Promise((resolve, reject) => {
            const { exec } = useAxios();
            if (!step.value) {
                reject();
            } else {
                exec(JobsAPI.retrieveNextStep(step.value.id)).then(async (stepResponse: any) => {
                    if (!stepResponse) {
                        resolve(null);
                    } else {
                        await exec(JobsAPI.getStepType(stepResponse.data.dataCheckinStepTypeId)).then(
                            (stepTypeResponse: any) => {
                                resolve({ ...stepTypeResponse.data, status: stepResponse.data.status });
                            },
                        );
                    }
                });
                // exec(JobsAPI.getJobSteps(step.value.dataCheckinJobId)).then(async (response: any) => {
                //     const steps = sort(ascend<any>(prop('order')), response.data);
                //     let nextStepIndex = null;
                //     for (let s = 0; s < steps.length; s++) {
                //         const stepObj = steps[s];
                //         if (step.value && stepObj.id === step.value.id) {
                //             nextStepIndex = s + 1;
                //             break;
                //         }
                //     }
                //     if (nextStepIndex && nextStepIndex < steps.length) {
                //         await exec(JobsAPI.getStepType(steps[nextStepIndex].dataCheckinStepTypeId)).then(
                //             (stepTypeResponse: any) => {
                //                 resolve(stepTypeResponse.data);
                //             },
                //         );
                //     } else {
                //         resolve(null);
                //     }
                // });
            }
        });
    };

    /**
     * Replaces the keys of an object with new keys
     * @param object An object
     * @param oldKeys The keys of an object
     * @param newKeys The new keys to replace old keys with
     */
    const renameFields = (object: object, oldKeys: string[], newKeys: string[]) => {
        const clonedObj = clone(object);
        oldKeys.forEach((key: string, index: number) => {
            const targetKey = clonedObj[key];
            delete clonedObj[key];
            clonedObj[newKeys[index]] = targetKey;
        });
        return clonedObj;
    };

    /**
     * Renames the sample's old field names with their mapped field ids
     * @param fields The fields as they are in the mapping configuration (source/target)
     * @param sample The job's sample
     */
    const renameSampleFields = (fields: any[], sample: any[]) => {
        // The sample's old field names
        const oldKeys = fields.map((field: any) => {
            if (field.source.path.length > 0) {
                return `${field.source.path.join('__')}__${field.source.title}`;
            }
            return field.source.title;
        });
        return sample.map((row: any) =>
            renameFields(
                row,
                oldKeys,
                fields.map((field: any) => field.target.id),
            ),
        );
    };

    const retrieveJobStep = (dcj: any, stepName: string) => {
        if (dcj) {
            return dcj.dataCheckinJobSteps.find((jobStep: any) => jobStep.dataCheckinStepType.name === stepName);
        }

        return null;
    };

    const getDroppedFieldIds = async (jobId: number) => {
        const { exec } = useAxios(true);
        try {
            const res = await exec(JobsAPI.getStep(jobId, 'anonymiser'));
            const anonymisation = res?.data;
            if (!isConfigEmpty(anonymisation.configuration)) {
                return pluck(
                    'id',
                    (anonymisation.configuration.fields as any[]).filter(
                        (field: any) =>
                            field.anonymisationType === 'identifier' && field.options.anonymisationMethod === 'drop',
                    ),
                );
            }
        } catch (err) {
            return [];
        }
        return [];
    };

    const updateAssetAfterFailedStep = async (dcj: any) => {
        const { exec } = useAxios(true);

        const harvester = retrieveJobStep(dcj, 'harvester');
        const mapping = retrieveJobStep(dcj, 'mapping');
        const cleaning = retrieveJobStep(dcj, 'cleaning');
        const encryption = retrieveJobStep(dcj, 'encryption');
        const loader = retrieveJobStep(dcj, 'loader');

        let dataset: any = null;
        await exec(AssetsAPI.getAsset(loader.configuration.collection)).then(async (res: any) => {
            dataset = res.data;
        });

        // update asset's fields based on each job step
        if (mapping) {
            if (!isConfigEmpty(mapping.configuration)) {
                const conceptIds = {
                    conceptIds: mapping.configuration.fields
                        .flatMap((field: any) => [...field.target.parentIds, field.target.id])
                        .filter((fieldId: any) => fieldId !== null),
                };
                conceptIds.conceptIds.push(mapping.configuration.domain.id); // domain id
                conceptIds.conceptIds.push(mapping.configuration.concept.id); // primary concept id
                let conceptUids: any = [];
                await exec(ModelAPI.conceptsUids(conceptIds)).then(async (res: any) => {
                    conceptUids = res.data;
                });
                dataset.standard = mapping.configuration.standard;
                const ids = await getDroppedFieldIds(dcj.id);
                dataset.structure = await getStructure(
                    harvester.configuration,
                    mapping.configuration,
                    conceptUids,
                    ids,
                );
                dataset.volume.unit = dataset.structure.type === 'json' ? 'records' : 'files';
                dataset.processingRules.mappingRules = mapping.configuration.fields.filter((obj: any) => {
                    return 'target' in obj && 'id' in obj.target && obj.target.id;
                });

                if (dataset.metadata.extent) {
                    if (dataset.metadata.extent.spatialCoverage && dataset.metadata.extent.spatialCoverage.field) {
                        const spatialCoverageFieldExists = dataset.structure.spatialFields.find(
                            (sfield: any) => sfield.name === dataset.metadata.extent.spatialCoverage.field.name,
                        );
                        if (!spatialCoverageFieldExists) {
                            dataset.metadata.extent.spatialCoverage = null;
                            dataset.status = AssetStatusCodes.Incomplete;
                        }
                    }

                    if (dataset.metadata.extent.temporalCoverage && dataset.metadata.extent.temporalCoverage.field) {
                        const temporalCoverageFieldExists = dataset.structure.temporalFields.find(
                            (tfield: any) => tfield.name === dataset.metadata.extent.temporalCoverage.field.name,
                        );
                        if (!temporalCoverageFieldExists) {
                            dataset.metadata.extent.temporalCoverage = null;
                            dataset.status = AssetStatusCodes.Incomplete;
                        }
                    }
                }
            }
        }

        if (cleaning) {
            if (!isConfigEmpty(cleaning.configuration)) {
                dataset.processingRules.cleaningRules = cleaning.configuration.fields;
            }
        }

        if (encryption) {
            if (!isConfigEmpty(encryption.configuration)) {
                dataset.processingRules.encryptionRules = encryption.configuration.fields.map((field: any) => {
                    return {
                        id: field.id,
                        uid: field.uid,
                        index: field.index,
                        encrypt: true,
                    };
                });
            }
        }

        // must update loader configuration before finalizing its previous step
        loader.configuration.schema = getLoaderSchema(dataset.structure);
        await exec(
            JobsAPI.updateStep(loader.id, {
                configuration: loader.configuration,
                serviceVersion: process.env.VUE_APP_LOADER_VERSION,
            }),
        );

        await exec(AssetsAPI.updateAsset(dcj.asset.id, dataset, false));
    };

    /**
     * @param field new field added in revised mapping
     * @returns mapped field with added fields required in anonymisation step
     */
    const addAnonymisationOptionsToField = (field: any) => {
        const obj = clone(field);
        obj.anonymisationType = 'insensitive';
        obj.generalization = null;
        obj.options = null;
        return obj;
    };

    /**
     *
     * @param field new field added in revised mapping
     * @param config configuration of encryption step
     * @param conceptUids
     * @returns mapped field with added fields required in encryption step
     */
    const addEncryptionOptionsToField = (field: any, config: any, conceptUids: any) => {
        const obj = clone(field);
        obj.index = false;
        obj.uid = conceptUids[field.id].uid;
        let currentConcept = config.schema[field.path[0]];
        for (let i = 1; i < field.path.length; i++) {
            const fieldName = field.path[i];
            currentConcept = currentConcept.children.find(
                (concept: any) =>
                    concept.key === fieldName ||
                    (fieldName.endsWith('[]') && fieldName.substring(0, fieldName.length - 2) === concept.key),
            );
        }
        const fieldInSchema = currentConcept.children.find(
            (concept: any) =>
                concept.key === field.title ||
                (field.title.endsWith('[]') && field.title.substring(0, field.title.length - 2) === concept.key),
        );
        const { indexed } = fieldInSchema;
        obj.canBeIndexed = indexed === true || indexed === 'true' || indexed === 'keyword' || indexed === 'both';
        return obj;
    };

    const getSchema = () => {
        return new Promise<any>((resolve) => {
            const { exec } = useAxios();
            let harvester: any = null;
            let mapping: any = null;
            if (step.value) {
                exec(JobsAPI.getStep(step.value.dataCheckinJobId, 'harvester')).then((resHarvester: any) => {
                    harvester = resHarvester.data;
                    if (step.value) {
                        exec(JobsAPI.getStep(step.value.dataCheckinJobId, 'mapping')).then((resMapping: any) => {
                            mapping = resMapping.data;
                            if (!isConfigEmpty(mapping.configuration)) {
                                const conceptIds = {
                                    conceptIds: mapping.configuration.fields
                                        .flatMap((field: any) => [...field.target.parentIds, field.target.id])
                                        .filter((fieldId: any) => fieldId !== null),
                                };
                                conceptIds.conceptIds.push(mapping.configuration.domain.id); // domain id
                                conceptIds.conceptIds.push(mapping.configuration.concept.id); // primary concept id
                                let conceptUids: any = null;

                                exec(ModelAPI.conceptsUids(conceptIds)).then(async (res: any) => {
                                    conceptUids = res.data;

                                    const ids = await getDroppedFieldIds(step.value?.dataCheckinJobId as number);
                                    const structure = await getStructure(
                                        harvester.configuration,
                                        mapping.configuration,
                                        conceptUids,
                                        ids,
                                    );
                                    const schema = getLoaderSchema(structure);
                                    resolve({ schema, conceptUids });
                                });
                            }
                        });
                    }
                });
            }
        });
    };

    /**
     * @param config configuration of the step
     * @param stepName
     * @param fieldsFromMapping fields extracted from mapping
     * @returns Updated configuration schema (if encryption step) and fields (by adding the ones that were added in
     *          revised mapping step, but not yet in the current step's configuration)
     */
    const addRevisedMappingNewFields = async (config: any, stepName: string, fieldsFromMapping: any) => {
        const stepConfig = clone(config);
        const addedFields: any = [];

        if (stepName === 'cleaning') {
            fieldsFromMapping.forEach((ef: any) => {
                const fieldExists = config.fields.find((cf: any) => cf.id === ef.id);

                if (!fieldExists) {
                    const fieldToBeAdded = clone(ef);
                    fieldToBeAdded.constraints = [];
                    addedFields.push(fieldToBeAdded);
                }
            });
        } else if (stepName === 'anonymiser') {
            fieldsFromMapping.forEach((ef: any) => {
                const fieldExists = config.fields.find((cf: any) => cf.id === ef.id);
                if (!fieldExists) {
                    const fieldToBeAdded = addAnonymisationOptionsToField(ef);
                    addedFields.push(fieldToBeAdded);
                }
            });
        }
        if (stepName === 'encryption') {
            await getSchema().then((resSchema: any) => {
                const { conceptUids } = resSchema;
                stepConfig.schema = { ...resSchema.schema };
                fieldsFromMapping.forEach((ef: any) => {
                    const fieldExists = config.fields.find((cf: any) => cf.id === ef.id);

                    if (!fieldExists) {
                        const fieldToBeAdded = addEncryptionOptionsToField(ef, stepConfig, conceptUids);
                        addedFields.push(fieldToBeAdded);
                    }
                });
            });
        }

        stepConfig.fields = [...stepConfig.fields, ...addedFields];

        return stepConfig;
    };

    /**
     * @param mappingFields fields configured in mapping
     * @param configuration configuration of the current step
     * @returns Step configuration with updated fields and schema (for encryption step)
     */
    const setupUpdatedConfiguration = (mappingFields: any, configuration: any) => {
        const config = clone(configuration);
        const extractedFieldsFromMapping = extractMappingFieldNames(mappingFields);
        const configFields = clone(configuration.fields);

        // locate the revised/ modified mappings and add them as modified to the configuration of the step
        extractedFieldsFromMapping.forEach((ef: any) => {
            const field = configFields.filter((cf: any) => cf.id === ef.id);
            if (field && field.length) {
                if ('modified' in ef) {
                    const idx: any = config.fields.findIndex((configField: any) => configField.id === ef.id);

                    if (idx >= 0) {
                        config.fields[idx].modified = ef.modified;
                    }
                }
            }
        });

        // calculate and remove deleted (from mapping) target fields
        configFields.forEach((cf: any) => {
            const mappedField = extractedFieldsFromMapping.filter((ef: any) => ef.id === cf.id);

            if (mappedField && !mappedField.length) {
                const idx: any = config.fields.findIndex((configField: any) => configField.id === cf.id);

                if (idx >= 0) {
                    config.fields.splice(idx, 1);
                }
            }
        });

        return addRevisedMappingNewFields(
            config,
            step.value ? (step?.value as any).dataCheckinStepType.name : null,
            extractedFieldsFromMapping,
        );
    };

    return {
        isConfigEmpty,
        isFinalized,
        getNextStep,
        renameSampleFields,
        updateAssetAfterFailedStep,
        canRestart,
        checkStepRestartEligibility,
        setupUpdatedConfiguration,
        getSchema,
    };
}
