








































































































































































































































































































































































































import { defineComponent, computed, ref, watch, reactive } from '@vue/composition-api';
import { ValidationProvider, extend, ValidationObserver } from 'vee-validate';
import { required, min_value, max_value } from 'vee-validate/dist/rules'; // eslint-disable-line
import { OrbitSpinner } from 'epic-spinners';
import { useAxios } from '@vue-composable/axios';
import { useFilters } from '@/app/composable';
import { AppAPI } from '@/modules/data-checkin/api';
import {
    FormBlock,
    JsonParser,
    TwButton,
    ConfirmModal,
    InputErrorIcon,
    AlertBanner,
    SvgImage,
    CopyToClipboardButton,
} from '@/app/components';
import { useHarvester } from '../../composable';

extend('required', {
    ...required,
});

extend('min_value', min_value);
extend('max_value', max_value);

const { formatBytes } = useFilters();

export default defineComponent({
    name: 'MqttConfiguration',
    model: {
        prop: 'configuration',
    },
    props: {
        configuration: {
            type: Object,
            required: true,
        },
        sample: {
            type: [Object, Array],
            required: false,
        },
        files: {
            type: Object,
            required: true,
        },
        activeTab: {
            type: Number,
            required: true,
        },
        jobId: {
            type: Number,
            required: true,
        },
        completed: {
            type: Boolean,
            default: true,
        },
        running: {
            type: Boolean,
            default: true,
        },
        initializing: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        FormBlock,
        ValidationProvider,
        JsonParser,
        ValidationObserver,
        TwButton,
        ConfirmModal,
        OrbitSpinner,
        InputErrorIcon,
        AlertBanner,
        SvgImage,
        CopyToClipboardButton,
    },
    setup(props, { root, emit }) {
        const { exec } = useAxios(true);
        const sampleFile = computed(() => props.files.sample);
        const sampleRef = ref<any>(null);
        const dateYesterday = computed(() => {
            const date = new Date();
            date.setDate(date.getDate() - 1);
            return date;
        });
        const mqttValidationRef = ref<any>(null);
        const showResetPasswordModal = ref<boolean>(false);
        const resettingPassword = ref<boolean>(false);
        const showPasswordBanner = ref<boolean>(true);
        const disableDataTypeChange = computed(() => props.completed);
        const invalidFormat = ref<boolean>(false);
        const initializationFailed = ref<boolean>(false);

        const tomorrow = computed(() => {
            const date = new Date();
            return date.setDate(date.getDate() + 1);
        });

        const resetButton = computed(() => {
            if (resettingPassword.value) return 'Resetting Password...';
            return 'Reset Password';
        });

        const connectionDetails = reactive({
            username: '',
            password: '',
            version: 3,
            transport: 'tcp',
            broker: '',
            clean_session: true,
            qos: 1,
            retain: false,
            uri: '',
            base_topic: '',
            topic: '',
            ssl: false,
            port: 1883,
        });

        const { parseJSON, checkInvalidXML, parseXML, clearFiles } = useHarvester(root, emit);

        // const extractMqttDetails = (response: any) => {
        //     if (response.status <= 399 && response.status >= 200) {
        //         const { data } = response;
        //         console.log(`uri=${data.uri}`);
        //         props.configuration.params.uri = '';
        //         props.configuration.params.base_topic = '';
        //         props.configuration.params.username = '';
        //         props.configuration.params.password = '';
        //         props.configuration.params.topic = '';
        //         try {
        //             const uri: string = new String(data.uri).toString();
        //             props.configuration.params.uri = uri;
        //             props.configuration.params.username = uri.replace('mqtts://', '').replace('mqtt://', '').split('@')[0].split(':')[0];
        //             props.configuration.params.topic = props.configuration.params.username;
        //             props.configuration.params.password = uri.replace('mqtts://', '').replace('mqtt://', '').split('@')[0].split(':')[1];
        //             props.configuration.params.port = uri.replace('mqtts://', '').replace('mqtt://', '').split('@')[1].split(':')[1];
        //             props.configuration.params.ssl = uri.includes('mqtts') ? true : false; 
        //             props.configuration.params.broker =  uri.replace('mqtts://', '').replace('mqtt://', '').split('@')[1].split(':')[0];
                    
        //         } catch (error) {
        //             console.error(error);
        //         }
        //     } else {
        //         console.log(response);
        //     }
        // };


        // const getMQTTAccount = async () => {
        //     const response: any = await exec(AppAPI.getMQTTAccount());
        //     extractMqttDetails(response);
        // }
   
        if (!props.initializing && !props.configuration.params.username) {
            emit('initialize-mqtt');
        }

        watch(
            () => props.configuration.params,
            (params) => {
                if (params) {
                    initializationFailed.value = false;
                    if (props.configuration.params.username) {
	 		            connectionDetails.broker = props.configuration.params.broker;
                        connectionDetails.topic = props.configuration.params.topic;
                        connectionDetails.username = props.configuration.params.username;
                        connectionDetails.password = props.configuration.params.password;
                        connectionDetails.ssl = props.configuration.params.ssl;
                        connectionDetails.port = props.configuration.params.port;
                        props.configuration.params.password = null;
			            props.configuration.params.uri = '';
			            emit('save-step');
                    } else {
                        initializationFailed.value = true;
                    }
                }
            },
            { immediate: true },
        );

        const acceptedFiles = computed(() => {
            switch (props.configuration.fileType) {
                case 'json':
                    return '.json';
                case 'xml':
                    return '.xml';
                default:
                    return '.*';
            }
        });

        const validateAndProceed = async () => {
            if (mqttValidationRef.value) {
                const valid = await mqttValidationRef.value.validate();
                if (valid) {
                    emit('next-tab');
                }
            }
        };

        const validate = async () => {
            return mqttValidationRef.value.validate();
        };

        const sampleUploaded = async (event: any) => {
            const file = event.target.files[0];
            await emit('sample-cropped', false);
            switch (props.configuration.fileType) {
                case 'json':
                    await emit('files-changed', { sample: file });
                    await parseJSON(file);
                    break;
                case 'xml':
                    invalidFormat.value = await checkInvalidXML(file);
                    if (invalidFormat.value) {
                        emit('files-changed', { sample: null });
                        (root as any).$toastr.e('Invalid xml format!', 'Error');
                    } else {
                        await emit('files-changed', { sample: file });
                        await parseXML(file);
                    }
                    break;
                default:
                // Do nothing
            }
            await validate();
        };

        const confirmResetPassword = () => {
            showResetPasswordModal.value = true;
        };
      
        const resetPassword = async () => {
            showResetPasswordModal.value = false;
            resettingPassword.value = true;
            const response: any = await exec(AppAPI.updateMQTTAccount(connectionDetails.username));
            if (response.status <= 399 && response.status >= 200) {
                connectionDetails.password = response.data.password;
                (root as any).$toastr.s('Mqtt password has been reset!', 'Success');
                
            } else {
                console.log(response);

                (root as any).$toastr.s('Failed to reset mqtt password', 'Failed');
            }
            resettingPassword.value = false;
        };

        const sampleMayBeCroppedMessage = computed(() =>
            props.configuration.fileType
                ? '- Sample may be cropped if required. Ensure that a small sample contains all necessary fields.'
                : '',
        );

        const sampleCroppedMessage = computed(() => (props.configuration.isSampleCropped ? 'cropped, ' : ''));

        return {
            tomorrow,
            resetButton,
            showResetPasswordModal,
            showPasswordBanner,
            connectionDetails,
            dateYesterday,
            mqttValidationRef,
            formatBytes,
            sampleFile,
            sampleRef,
            sampleUploaded,
            validate,
            validateAndProceed,
            confirmResetPassword,
            resetPassword,
            resettingPassword,
            clearFiles,
            acceptedFiles,
            disableDataTypeChange,
            invalidFormat,
            sampleCroppedMessage,
            sampleMayBeCroppedMessage,
            initializationFailed,
        };
    },
});
