
































































import { ref } from '@vue/composition-api';
import {renamings} from '@/app/utilities';
export default {
    name: 'TransformationDetails',
    props: {
        transformation: {
            type: Array,
            required: true,
        },
        readonly: {
            type: Boolean,
            required: true,
        },
        isFail: {
            type: Boolean,
            default: null,
        },
    },
    setup() {
        const inHoverMode = ref(false);

        return { inHoverMode,renamings };
    },
};
