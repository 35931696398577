






























































































import * as R from 'ramda';
import { defineComponent, computed, ref, watch, onUpdated, inject } from '@vue/composition-api';
import { OrbitSpinner } from 'epic-spinners';
import { useAxios } from '@vue-composable/axios';
import { JobOverview } from '@/modules/data-checkin/components';
import { Card, ConfirmModal, SvgImage, Pagination } from '@/app/components';
import { useErrors, useQuery, useResult } from '@/app/composable';
import store from '@/app/store';
import { StatusCode } from '../constants';
import GET_JOBS from '../graphql/jobs.graphql';
import { JobsAPI } from '../api';

export default defineComponent({
    name: 'DataCheckinJobs',
    components: { Card, ConfirmModal, OrbitSpinner, JobOverview, SvgImage, Pagination },
    setup(props, { root }) {
        // Delete confirmation modal and pointer
        const renamings = inject("renamings");
        const showDeleteModal = ref<boolean>(false);
        const toBeDeleted = ref<number | null>(null);
        const fromNotificationId = ref<string>(root.$route.params.id ?? null);
        const jobStatus = ref(root.$route.params.status ?? null);
        const notificationReferenceType = ref(root.$route.params.type ?? null) as any;
        const dcjDeleted = ref(false);
        const scrollTo = ref(false);
        const dataCheckinJobsElement = ref<HTMLElement>();

        watch(showDeleteModal, (value: boolean) => {
            if (!value) toBeDeleted.value = null;
        });

        const { exec } = useAxios();
        const { checkGQLAuthentication } = useErrors(root.$route);

        const { loading, error, result, onError } = useQuery(GET_JOBS, {}, { fetchPolicy: 'no-cache' });
        onError(checkGQLAuthentication);
        const jobs = useResult(result, null, (data: any) => data.jobs);
        const edit = (id: number) => {
            root.$router.push({ name: 'data-checkin-jobs:edit', params: { id: `${id}` } });
        };

        const openHistory = (id: number) => {
            root.$router.push({ name: 'dcj:history', params: { id: `${id}` } });
        };

        const pageSize = 10;
        const page = ref<number>(1);
        const setPage = (newPage: number) => {
            page.value = newPage;
            if (dataCheckinJobsElement.value) {
                dataCheckinJobsElement.value.scrollIntoView({ behavior: 'smooth' });
            }
        };
        const currentPage = computed({
            get: () => page.value,
            set: (newPage: number) => {
                setPage(newPage);
            },
        });

        const sortedJobs = computed(() => {
            if (jobs.value) {
                return R.sortWith([R.descend(R.prop('updatedAt'))], jobs.value as any[]);
            }
            return [];
        });

        // calculate in which page is the dcj which was referred by the notification
        const calculatePageOfJob = (sJobs: any) => {
            const idx = R.findIndex(R.propEq('id', fromNotificationId.value.toString()), sJobs as any[]);
            if (~idx) {
                setPage(Math.floor(idx / pageSize) + 1);
            } else {
                dcjDeleted.value = true;
            }
        };

        const visibleJobs = computed(() => {
            if (sortedJobs.value) {
                if (fromNotificationId.value && !scrollTo.value) {
                    scrollTo.value = true;
                }

                return sortedJobs.value.slice((page.value - 1) * pageSize, (page.value - 1) * pageSize + pageSize);
            }
            return [];
        });

        const confirmDelete = (id: number) => {
            toBeDeleted.value = id;
            showDeleteModal.value = true;
        };

        const destroy = async () => {
            if (toBeDeleted.value) {
                const idx = R.findIndex(R.propEq('id', toBeDeleted.value), jobs.value as any[]);
                if (~idx) {
                    showDeleteModal.value = false;
                    try {
                        const referenceId = toBeDeleted.value;
                        await exec(JobsAPI.delete(toBeDeleted.value));

                        store.commit.notificationEngine.SET_NOTIFICATIONS_AFTER_REFERENCED_ITEM_DELETE({
                            referenceId,
                            referenceType: notificationReferenceType.value
                                ? notificationReferenceType.value
                                : 'DataCheckinJob',
                        });
                        (root as any).$toastr.s('Data Check-in Job deleted successfuly', 'Success');
                        jobs.value.splice(idx, 1);
                    } catch (e) {
                        (root as any).$toastr.e('Deleting Data Check-in Job failed', 'Error');
                    }
                }
            }
        };

        /**
         * If the loader step (i.e. last step) of a dcj has a failed/ completed status, then the background of the dcj should be based on that,
         * otherwise the status referred in the notification
         */
        const changeBackground = (job: any) => {
            let lastStepLoader = null;
            if (job) {
                lastStepLoader = job.dataCheckinJobSteps.find(
                    (jobStep: any) => jobStep.dataCheckinStepType.name === 'loader',
                );
            }
            if (root.$route.params && String(job.id) === String(root.$route.params.id)) {
                if (lastStepLoader.status === StatusCode.Failed || lastStepLoader.status === StatusCode.Completed) {
                    return lastStepLoader.status === StatusCode.Completed ? 'bg-yellow-100' : 'bg-red-100';
                }

                return jobStatus.value === 'dcj.service.completed' ? 'bg-yellow-100' : 'bg-red-100';
            }
            return '';
        };

        const expandDCJ = (id: number | string) => {
            if (root.$route.params && id == root.$route.params.id) return true;
            return false;
        };

        onUpdated(() => {
            if (scrollTo.value) {
                calculatePageOfJob(sortedJobs.value);
                const element = document.getElementById(fromNotificationId.value);
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' });
                    scrollTo.value = false;
                    fromNotificationId.value = '';
                }
            }
        });

        return {
            renamings,
            confirmDelete,
            currentPage,
            destroy,
            edit,
            error,
            jobs,
            loading,
            pageSize,
            visibleJobs,
            showDeleteModal,
            fromNotificationId,
            jobStatus,
            dcjDeleted,
            changeBackground,
            page,
            setPage,
            expandDCJ,
            openHistory,
            dataCheckinJobsElement,
        };
    },
});
