import { Status } from '@/modules/data-model/constants';
import { Ref } from '@vue/composition-api';
import dayjs from 'dayjs';
import * as R from 'ramda';
import { FieldConfiguration, MappingConfig, Metadata, Source } from '../views/mapping/mapping.types';

const checkType = (value: any): string => {
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(value)) {
        console.log(value);
        console.log(R.type(value));
        console.log(R.type(['Object', 'Array'].indexOf(R.type(value)) ));
        if ( ['Object', 'Array'].indexOf(R.type(value)) === -1 &&  dayjs(Date.parse(value)).isValid()) {
            return 'Date';
        }

        return R.type(value);
    }

    return R.type(value); // Booleans are considered numbers, thus we need to account for that as well
};

const fieldType = (data: Record<string, any>[]) => {
    const types = R.uniq(R.map(checkType, data));
    if (R.contains('Array', types)) return 'array';
    if (R.contains('Object', types)) return 'object';
    if (R.contains('String', types)) return 'string';
    if (R.contains('Date', types)) return 'date';
    if (R.contains('Number', types)) return 'number';
    if (R.contains('Boolean', types)) return 'boolean';
    if (types.length === 1 && types[0] === 'Null') return 'null';
    return '';
};

export enum MappingFilter {
    All = 'all',
    Corrected = 'corrected',
    Invalid = 'invalid',
    Predicted = 'predicted',
    Selected = 'selected',
    Unidentified = 'unidentified',
}

export enum TransformationType {
    Timezone,
    TypeCast,
    Unit,
}

export interface TransformationInfo {
    type: TransformationType;
    unit?: string;
    source: string;
    target?: string;
}

export const validateConfiguration = (configuration: Ref<MappingConfig>) => {
    const regex = /\[.*?\]/;
    const hasOrder = R.has('order');
    if (configuration.value) {
        configuration.value.fields.forEach((field: FieldConfiguration) => {
            field.temp.invalid = false; // eslint-disable-line no-param-reassign
            if (field.target.id === null) return; // Not testing for empty targets

            // Calculate duplicates, to be used below
            const duplicates = configuration.value.fields.filter((obj) => {
                if (obj.target.id === null) return false;

                return obj.target.id === field.target.id && obj.target.path.join('__') === field.target.path.join('__');
            });

            if (field.transformation) {
                // Missing unit transformation
                if (
                    field.transformation.measurementType &&
                    field.transformation.measurementType !== 'Not relevant' &&
                    field.transformation.sourceUnit === null
                ) {
                    field.temp.invalid = true; // eslint-disable-line no-param-reassign
                }

                // Missing date format
                if (field.transformation.sourceDateFormat === null) {
                    field.temp.invalid = true; // eslint-disable-line no-param-reassign
                }

                // Missing timezone
                if (field.transformation.sourceTimezone === null) {
                    field.temp.invalid = true; // eslint-disable-line no-param-reassign
                }

                // More arrays in target path than source path
                if (R.has('oneElementArrays', field.transformation)) {
                    if (
                        field.target.path.filter((p) => p.includes('[]')).length -
                            field.source.path.filter((p) => p.includes('[]')).length !==
                        field.transformation.oneElementArrays?.length
                    ) {
                        field.temp.invalid = true;
                    }
                }
            }

            // Check if is array and not a multiple field
            if (regex.test(field.source.type) && !field.transformation?.multiple) {
                field.temp.invalid = true; // eslint-disable-line no-param-reassign
            }

            // Check for same level of nesting with arrays
            const arraysInSouce = field.source.path.filter((path: string) => path.endsWith('[]')).length;
            const arraysInTarget = field.target.path.filter((path: string) => path.endsWith('[]')).length;
            if (arraysInSouce > arraysInTarget) {
                field.temp.invalid = true; // eslint-disable-line no-param-reassign
            }

            if (field.transformation?.multiple && field.source.type.startsWith('array [') && duplicates.length > 1) {
                field.temp.invalid = true; // eslint-disable-line no-param-reassign
            }

            if (field.transformation && hasOrder(field.transformation)) {
                // Multiple / Ordered fields
                // Missing order
                if (field.transformation.order === null) {
                    field.temp.invalid = true; // eslint-disable-line no-param-reassign
                } else if (
                    duplicates.filter((obj) => obj.transformation?.order === field.transformation?.order).length > 1
                ) {
                    // Duplicate order
                    field.temp.invalid = true; // eslint-disable-line no-param-reassign
                }
            } else if (duplicates.length > 1) {
                // Duplicate field (not multiple)
                field.temp.invalid = true; // eslint-disable-line no-param-reassign
            }
        });
    }
};

export const getTransformations = (field: Readonly<any>): Array<TransformationInfo> => {
    const transformations: Array<TransformationInfo> = [];

    transformations.push({
        type: TransformationType.TypeCast,
        source: field.transformation?.sourceDateFormat,
        target: field.target.type,
    });

    if (field.transformation?.sourceTimezone) {
        transformations.push({
            type: TransformationType.Timezone,
            source: field.transformation?.sourceTimezone,
        });
    }
    if (
        field.transformation?.sourceUnit &&
        field.transformation.targetUnit &&
        field.transformation.measurementType &&
        field.transformation?.sourceUnit !== field.transformation.targetUnit
    ) {
        transformations.push({
            type: TransformationType.Unit,
            source: field.transformation?.sourceUnit,
            target: field.transformation?.targetUnit,
            unit: field.transformation?.measurementType,
        });
    }

    return transformations;
};

export const getTransformationText = (transformation: TransformationInfo, hasStats = false) => {
    if (transformation.type === TransformationType.TypeCast) {
        if (transformation.target === 'integer') {
            return [
                hasStats
                    ? 'Field values were transformed and truncated to'
                    : 'Field values will be transformed and truncated to',
                transformation.target,
                'data type, if required',
            ];
        }
        if (transformation.target === 'datetime') {
            return [
                hasStats ? 'Field values were transformed to' : 'Field values will be transformed to',
                transformation.target,
                'data type, from',
                transformation.source,
                'to',
                'ISO 8601',
            ];
        }
        if (transformation.target === 'date') {
            return [
                hasStats ? 'Field values were transformed to' : 'Field values will be transformed to',
                transformation.target,
                'data type, from',
                transformation.source,
            ];
        }
        return [
            hasStats ? 'Field values were transformed to' : 'Field values will be transformed to',
            transformation.target,
            'data type.',
        ];
    }
    if (transformation.type === TransformationType.Timezone) {
        return [
            hasStats ? 'Field values wwere transformed to' : 'Field values will be transformed to',
            transformation.source,
            hasStats
                ? 'timezone, if there was no timezone information in the data.'
                : 'timezone, if there is no timezone information in the data.',
        ];
    }
    if (transformation.type === TransformationType.Unit) {
        return [
            'Field values of measurement type',
            transformation.unit,
            hasStats ? 'were transformed from' : 'will be transformed from',
            transformation.source,
            'to',
            transformation.target,
        ];
    }
    return [''];
};

export function useMapping(sample: any[], rootConcept: any) {
    let id = 1; // Auto-increment identifier for source fields. Required for mapping

    /**
     * Extract an array of values, using the provided sample
     * @param title The title of the field to search for
     * @param path The path of the field to search for
     */
    const extractFieldSample = (title: string, path: string[]): any[] => {
        const finalPaths: any[] = [[]]; // Contains the paths to get samples from every record
        path.forEach((subPath: any) => {
            if (subPath.endsWith('[]')) {
                finalPaths.forEach((fPath: any) => {
                    fPath.push(subPath.slice(0, -2)); // Remove [] from subPath
                    const subPathSamples: any[] = R.map(R.path(fPath), sample); // Contains the samples from every record for subPath
                    let subPathSamplesLength = 0;
                    subPathSamples.forEach((subPathSample: any) => {
                        if (subPathSample && subPathSample.length > subPathSamplesLength) {
                            subPathSamplesLength = subPathSample.length;
                        }
                    });

                    // Calculate all the paths based on subPath's sample length
                    for (let i = 0; i < subPathSamplesLength; i += 1) {
                        if (i !== 0) {
                            const newPath = R.clone(fPath);
                            newPath.pop();
                            newPath.push(i);
                            finalPaths.push(newPath);
                        } else {
                            fPath.push(i);
                        }
                    }
                });
            } else {
                // Add subPath to all paths
                finalPaths.forEach((p: any) => p.push(subPath));
            }
        });

        // Add title to all paths
        finalPaths.forEach((p: any) => p.push(title));

        // Concatenate the samples from every record
        let allSamples: any[] = [];
        finalPaths.forEach((fPath: any[]) => {
            const fPathSample = R.map(R.path(fPath), sample);
            allSamples = allSamples.concat(fPathSample);
        });
        return allSamples;
    };

    /**
     * Recursive function to extract all fields from the given sample
     * @param title The title of the field
     * @param path The path of the field
     */
    const extractFields = (title: string, path: string[]): Source[] => {
        const fieldSample = extractFieldSample(title, path);
        const type = title === '_uploaded_file' ? 'base64binary' : fieldType(fieldSample);
        const notEmptySample = fieldSample.find((fs: any) => fs);
        if (type === 'array') {
            if (fieldSample.length > 0) {
                let fieldSamples: any[] = [];
                fieldSample.forEach((fs) => {
                    fieldSamples = fieldSamples.concat(fs);
                });
                const arrayType = fieldType(fieldSamples);
                if (
                    arrayType === 'string' ||
                    arrayType === 'number' ||
                    arrayType === 'date' ||
                    arrayType === 'boolean'
                ) {
                    return [{ id: id++, title, path, type: `array [${arrayType}]` }];
                }

                if (arrayType === 'object') {
                    if (notEmptySample) {
                        const field = notEmptySample.find((fs: any) => fs);
                        if (field) {
                            const keys = Object.keys(field);
                            return keys.reduce((sources: Source[], key: string) => {
                                sources.push(...extractFields(key, [...path, `${title}[]`]));
                                return sources;
                            }, []);
                        }
                    }
                }
            }
            return [];
        }

        if (type === 'object') {
            if (notEmptySample) {
                const keys = Object.keys(notEmptySample);
                return keys.reduce((sources: Source[], key: string) => {
                    sources.push(...extractFields(key, [...path, title]));
                    return sources;
                }, []);
            }
        }

        return [{ id: id++, title, path, type }];
    };

    /**
     * Returns a blank field
     * @param source
     */
    const initEmptyField = (source: Source): FieldConfiguration => {
        return {
            source,
            target: {
                id: null,
                title: null,
                parentIds: [rootConcept.id],
                type: null,
                path: [rootConcept.name],
                categories: [rootConcept.name],
            },
            prediction: undefined,
            temp: {
                invalid: false,
            },
        };
    };

    /**
     * Initialize the mapping, using the sample provided
     */
    const initialize = (): FieldConfiguration[] => {
        const keys = Object.keys(sample[0]);
        const result: Source[] = [];
        keys.forEach((key: string) => {
            result.push(...extractFields(key, []));
        });
        return result.reduce((fields: FieldConfiguration[], source: Source) => {
            fields.push(initEmptyField(source));
            return fields;
        }, []);
    };

    /**
     * Extracts the value of a field given the path
     *
     * @param keepSelections Whether to return existing value
     * @param field - The field to query
     * @param path - The path of interest
     * @param defaultValue - The default value to return if we are not
     * interested in the existing value of the field
     */
    const extractFieldValue = (
        keepSelections: boolean,
        field: FieldConfiguration,
        path: string[],
        defaultValue: any = null,
    ) => {
        if (!keepSelections) {
            return defaultValue;
        }

        return R.path(path, field);
    };

    /**
     * Returns a clone of a field, updated with information from an assigned concept
     * @param field The field to be updated ()
     * @param concept The concept assigned to a field
     * @param prediction The prediction result
     */
    const createFieldConfiguration = (
        field: FieldConfiguration,
        concept: any,
        prediction: any,
        keepSelections = false,
    ): FieldConfiguration => {
        let newField = {
            source: field.source,
            target: {
                ...field.target,
                id: concept.id,
                title: concept.name,
                type: concept.type,
            },
            transformation: {},
            metadata: R.pick(['index', 'temporal', 'spatial'], concept.metadata) as Metadata,
            temp: {
                ...field.temp,
                userDefined: prediction === null,
            },
        };

        if (prediction) {
            newField = R.assocPath(['prediction'], prediction, newField);
        } else if (R.hasPath(['prediction'], newField)) {
            newField = R.assocPath(['prediction'], R.clone(field.prediction), newField);
        }

        // Date transformations
        if (concept.type === 'datetime') {
            newField = R.assocPath(
                ['transformation', 'sourceTimezone'],
                extractFieldValue(keepSelections, newField, ['transformation', 'sourceTimezone']),
                newField,
            );
            newField = R.assocPath(
                ['transformation', 'sourceDateFormat'],
                extractFieldValue(keepSelections, newField, ['transformation', 'sourceDateFormat']),
                newField,
            );
        }

        if (concept.type === 'date') {
            newField = R.assocPath(
                ['transformation', 'sourceDateFormat'],
                extractFieldValue(keepSelections, newField, ['transformation', 'sourceDateFormat']),
                newField,
            );
        }

        if (concept.type === 'time') {
            newField = R.assocPath(
                ['transformation', 'sourceTimezone'],
                extractFieldValue(keepSelections, newField, ['transformation', 'sourceTimezone']),
                newField,
            );
        }

        if (concept.type === 'double') {
            newField = R.assocPath(['transformation', 'thousandsSeperator'], '', newField);
            newField = R.assocPath(['transformation', 'decimalPoint'], '.', newField);
        }

        // More arrays in target path than source path
        if (
            field.source.path.some((p) => p.includes('[]')) &&
            field.target.path.filter((p) => p.includes('[]')).length >
                field.source.path.filter((p) => p.includes('[]')).length
        ) {
            newField = R.assocPath(['transformation', 'oneElementArrays'], [], newField);
        }

        // Multiple (and ordered) fields
        if (concept.metadata.multiple) {
            newField = R.assocPath(
                ['transformation', 'multiple'],
                extractFieldValue(keepSelections, newField, ['transformation', 'multiple'], true),
                newField,
            );
            if (concept.metadata.ordered) {
                newField = R.assocPath(
                    ['transformation', 'order'],
                    extractFieldValue(keepSelections, newField, ['transformation', 'order']),
                    newField,
                );
            }
        }

        // Unit Transformations
        if (concept.metadata.measurementType) {
            newField = R.assocPath(
                ['transformation', 'measurementType'],
                extractFieldValue(
                    keepSelections,
                    newField,
                    ['transformation', 'measurementType'],
                    concept.metadata.measurementType,
                ),
                newField,
            );
            newField = R.assocPath(
                ['transformation', 'sourceUnit'],
                extractFieldValue(keepSelections, newField, ['transformation', 'sourceUnit']),
                newField,
            );
            newField = R.assocPath(
                ['transformation', 'targetUnit'],
                extractFieldValue(
                    keepSelections,
                    newField,
                    ['transformation', 'targetUnit'],
                    concept.metadata.measurementUnit,
                ),
                newField,
            );
        }

        return newField;
    };

    /**
     * Prepares the payload to be used with the predictor backend
     * @param fields The list of the selected fields
     * @param domain The domain id for this mapping
     * @param standard The standard (if any) the data conforms to
     * @param concept The concept id these fields belong to
     */
    const getPredictionPayload = (fields: Readonly<any[]>, domain: number, standard: any, concept: number) => ({
        metadata: {
            domain,
            standard,
            concept,
        },
        sample,
        fields,
        version: 2,
        configuration: {
            method: { matchers: ['es', 'fz'], scoring: 'default' },
        },
    });

    /**
     * Constructs and returns the fields with the new names after mapping.
     * @param fields The list of fields before mapping
     */
    const extractMappingFieldNames = (fields: Array<any[]>) => {
        const mappingFields: any[] = [];
        fields
            .filter(
                // If multiple fields are mapped to the same field under the same path, only show it once
                (field: any, index: number) =>
                    field.target &&
                    (fields.map((f: any) => f.target.id).indexOf(field.target.id) === index ||
                        fields.map((f: any) => f.target.path.join('_')).indexOf(field.target.path.join('_')) === index),
            )
            .forEach((field: any) => {
                if (field.target && field.target.id) {
                    // add mapped field target object
                    const obj = field.target;
                    const defaultKey = field.target.title;

                    if (field.transformation) {
                        obj.multiple = field.transformation.multiple;
                    }
                    // if mapped field has a path then add it to the name
                    if (field.target.path.length > 0) {
                        const path = field.target.path.map((pathField: string) => pathField.replace('[]', '__0'));
                        obj.name = `${path.join('__')}__${defaultKey}`;
                    } else {
                        obj.name = defaultKey;
                    }

                    // if the field has been revised in mapping
                    if (field.temp && 'modified' in field.temp) {
                        obj.modified = field.temp.modified;
                    }

                    obj.originalName = field.source.title;
                    obj.originalPath = field.source.path;

                    mappingFields.push(obj);
                }
            });
        return mappingFields;
    };

    /**
     * Migrates an unfinished mapping to a newer version of the model
     *
     * @param domain - The mapped model
     * @param concept - The mapped concept
     * @param fields - The mapped fields
     * @param idMappings - Mappings from old ids to new concepts
     * @param mainConceptDeprecated - Whether the parent concept is deprecated or not
     */
    const migrate = (
        domain: any,
        concept: any,
        fields: FieldConfiguration[],
        idMappings: any,
        mainConceptDeprecated = false,
    ): { fields: any[]; domain: any; concept: any; deprecatedFields: string[] } => {
        // reconstructing the mapped fields
        const mappedFields = [];
        const deprecatedFields: string[] = [];
        for (let idx = 0; idx < fields.length; idx++) {
            const field: FieldConfiguration = fields[idx];

            // if the parent is not deprecated and we have a target id
            // then we recreate the field with the new concept object
            if (!mainConceptDeprecated && field.target.id && field.target.title) {
                const newConcept = idMappings[field.target.id];
                if (newConcept.status !== Status.Deprecated) {
                    mappedFields.push(createFieldConfiguration(field, newConcept, field.prediction, true));
                } else {
                    deprecatedFields.push(field.target.title);
                    mappedFields.push(initEmptyField(field.source));
                }
            } else {
                // otherwise we store a blank field
                mappedFields.push(initEmptyField(field.source));
            }
        }

        let newDomain = R.clone(domain);
        let newConcept = R.clone(concept);
        // replace old fields with new ones
        if (!mainConceptDeprecated) {
            // if concept is not deprecated
            // replace the domain and concept information
            newDomain.name = idMappings[domain.id].name;
            newDomain.id = idMappings[domain.id].id;
            newConcept.name = idMappings[concept.id].name;
            newConcept.id = idMappings[concept.id].id;
        } else {
            newDomain = null;
            newConcept = null;
        }

        return {
            fields: mappedFields,
            domain: newDomain,
            concept: newConcept,
            deprecatedFields,
        };
    };

    return {
        createFieldConfiguration,
        extractFieldSample,
        getPredictionPayload,
        initialize,
        validateConfiguration,
        extractMappingFieldNames,
        initEmptyField,
        migrate,
        getTransformationText,
    };
}
