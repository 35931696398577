var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form-modal',{attrs:{"title":_vm.title},on:{"cancel":_vm.cancel,"save":_vm.validateAndProceed}},[(_vm.param.value.type === 'dynamic')?_c('alert-banner',{staticClass:"text-sm",attrs:{"type":"warn"},scopedSlots:_vm._u([{key:"description",fn:function(){return [_vm._v(" The dynamic values are calculated based on the UTC timezone, at the execution time of the step. ")]},proxy:true}],null,false,3505795666)}):_vm._e(),_c('validation-observer',{ref:"paramValidationRef",staticClass:"flex flex-col space-y-5"},[_c('div',{staticClass:"space-y-5"},[_c('div',{staticClass:"flex flex-wrap -mx-3"},[_c('div',{staticClass:"w-full px-3 mb-6 md:w-1/2 md:mb-0"},[_c('label',{staticClass:"block mb-2 text-xs font-bold tracking-wide uppercase text-neutral-600",attrs:{"for":"parameter"}},[_vm._v(" Parameter ")]),_c('validation-provider',{attrs:{"rules":{ regex: /^[A-Za-z0-9\._-]+$/, required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"relative"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.param.key.name),expression:"param.key.name",modifiers:{"trim":true}}],staticClass:"block w-full px-2 py-1 text-sm form-input",class:{
                                    'bg-neutral-200':
                                        _vm.editParamType === 'url' ||
                                        _vm.editParamType === 'pagination' ||
                                        _vm.param.key.type === 'body',
                                    'border-red-700': errors.length > 0,
                                },attrs:{"id":"parameter","type":"text","placeholder":"New parameter","disabled":_vm.editParamType === 'url' ||
                                    _vm.editParamType === 'pagination' ||
                                    _vm.param.key.type === 'body'},domProps:{"value":(_vm.param.key.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.param.key, "name", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(errors.length > 0)?_c('input-error-icon'):_vm._e()],1),_c('div',{staticClass:"mt-1 text-xs text-red-700"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"w-full px-3 mb-6 md:w-1/2 md:mb-0"},[_c('label',{staticClass:"block mb-2 text-xs font-bold tracking-wide uppercase text-neutral-600",attrs:{"for":"valueType"}},[_vm._v(" Value Type ")]),_c('div',{staticClass:"relative"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var errors = ref.errors;
return [_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.param.value.type),expression:"param.value.type"}],staticClass:"block w-full px-2 py-1 text-sm form-input bg-none",class:{
                                    'text-neutral-500': _vm.param.value.type === null,
                                    'bg-neutral-200': _vm.editParamType === 'pagination',
                                    'border-red-700': errors.length > 0,
                                },attrs:{"id":"valueType","disabled":_vm.editParamType === 'pagination'},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.param.value, "type", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){return _vm.changeType()}]}},[_c('option',{attrs:{"disabled":""},domProps:{"value":null}},[_vm._v("Select type...")]),(_vm.editParamType && _vm.editParamType === 'pagination')?_c('option',{staticClass:"text-neutral-800",attrs:{"value":"pagination"}},[_vm._v(" Pagination ")]):_vm._e(),_vm._l((_vm.parameterTypes),function(type){return [(
                                            type.value !== 'authentication' ||
                                            (type.value === 'authentication' && _vm.loginResponseKeys.length > 0)
                                        )?_c('option',{key:type.value,staticClass:"text-neutral-800",domProps:{"value":type.value}},[_vm._v(" "+_vm._s(type.label)+" ")]):_vm._e()]})],2),(errors.length > 0)?_c('input-error-icon',{staticClass:"mr-4"}):_vm._e()]}}])}),_c('div',{staticClass:"\n                                absolute\n                                inset-y-0\n                                right-0\n                                flex\n                                items-center\n                                px-2\n                                text-gray-700\n                                pointer-events-none\n                            "},[_c('svg',{staticClass:"w-4 h-4 fill-current",attrs:{"viewBox":"0 0 20 20"}},[_c('path',{attrs:{"d":"M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"}})])])],1)])]),(_vm.param.value.type && _vm.param.value.type !== 'dynamic' && _vm.param.value.type !== 'authentication')?_c('div',{staticClass:"flex flex-wrap mb-6 -mx-3"},[_c('div',{staticClass:"w-full px-3"},[_c('label',{staticClass:"block mb-2 text-xs font-bold tracking-wide uppercase text-neutral-600",attrs:{"for":"value"}},[_vm._v(" Value ")]),_c('div',{staticClass:"relative"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.param.value.value),expression:"param.value.value",modifiers:{"trim":true}}],staticClass:"block w-full px-2 py-1 text-sm form-input",class:{ 'border-red-700': errors.length > 0 },attrs:{"id":"value","type":[
                                    _vm.param.value.type === 'integer' ||
                                    _vm.param.value.type === 'float' ||
                                    _vm.editParamType === 'pagination'
                                        ? 'number'
                                        : 'text' ],"step":[_vm.param.value.type === 'float' ? 0.00000000001 : null]},domProps:{"value":(_vm.param.value.value)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.param.value, "value", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(errors.length > 0)?_c('input-error-icon'):_vm._e()]}}],null,false,555619186)})],1)])]):_vm._e(),(_vm.param.value.type && _vm.param.value.type === 'authentication')?_c('div',{staticClass:"w-full"},[_c('label',{staticClass:"block mb-2 text-xs font-bold tracking-wide uppercase text-neutral-600",attrs:{"for":"authenticationValue"}},[_vm._v(" Authentication Value ")]),_c('div',{staticClass:"relative"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.param.value.value),expression:"param.value.value"}],staticClass:"block w-full px-2 py-1 text-sm bg-none form-input",class:{
                                'text-neutral-500': _vm.param.value.value === null,
                                'border-red-700': errors.length > 0,
                            },attrs:{"id":"authenticationValue"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.param.value, "value", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"disabled":""},domProps:{"value":null}},[_vm._v("Select authentication value...")]),_vm._l((_vm.loginResponseKeys),function(value){return _c('option',{key:value,staticClass:"text-neutral-800",domProps:{"value":value}},[_vm._v(" "+_vm._s(value)+" ")])})],2),(errors.length > 0)?_c('input-error-icon',{staticClass:"mr-4"}):_vm._e()]}}],null,false,3969657129)}),_c('div',{staticClass:"absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none"},[_c('svg',{staticClass:"w-4 h-4 fill-current",attrs:{"viewBox":"0 0 20 20"}},[_c('path',{attrs:{"d":"M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"}})])])],1)]):_vm._e(),(_vm.param.value.type && _vm.param.value.type === 'dynamic')?_c('div',{staticClass:"w-full"},[_c('label',{staticClass:"block mb-2 text-xs font-bold tracking-wide uppercase text-neutral-600",attrs:{"for":"dynamicOption"}},[_vm._v(" Dynamic Value ")]),_c('div',{staticClass:"relative"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.dynamicValue.option),expression:"dynamicValue.option"}],staticClass:"block w-full px-2 py-1 text-sm bg-none form-input",class:{
                                'text-neutral-500': _vm.dynamicValue.option === null,
                                'border-red-700': errors.length > 0,
                            },attrs:{"id":"dynamicOption"},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.dynamicValue, "option", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){return _vm.changeDynamicValue()}]}},[_c('option',{attrs:{"disabled":""},domProps:{"value":null}},[_vm._v("Select dynamic value...")]),_vm._l((_vm.dynamicValues),function(value){return _c('option',{key:value,staticClass:"text-neutral-800",domProps:{"value":value}},[_vm._v(" "+_vm._s(value)+" ")])})],2),(errors.length > 0)?_c('input-error-icon',{staticClass:"mr-4"}):_vm._e()]}}],null,false,4172372284)}),_c('div',{staticClass:"absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none"},[_c('svg',{staticClass:"w-4 h-4 fill-current",attrs:{"viewBox":"0 0 20 20"}},[_c('path',{attrs:{"d":"M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"}})])])],1)]):_vm._e(),(_vm.dynamicValue.option)?_c('div',{staticClass:"flex flex-wrap mb-6 -mx-3"},[(_vm.dynamicValue.option)?_c('div',{staticClass:"w-full px-3 md:mb-0",class:{ 'md:w-1/2': _vm.needsValue }},[_c('label',{staticClass:"block mb-2 text-xs font-bold tracking-wide uppercase text-neutral-600",attrs:{"for":"format"}},[_vm._v(" Format ")]),_c('div',{staticClass:"relative"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.param.value.format),expression:"param.value.format"}],staticClass:"block w-full px-2 py-1 text-sm bg-none form-input",class:{
                                    'text-neutral-500': _vm.param.value.format === null,
                                    'border-red-700': errors.length > 0,
                                },attrs:{"id":"format"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.param.value, "format", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"disabled":""},domProps:{"value":null}},[_vm._v("Select format...")]),_vm._l((_vm.formats),function(format,index){return _c('option',{key:index,staticClass:"text-neutral-800",domProps:{"value":format.value}},[_vm._v(" "+_vm._s(format.label)+" ")])})],2),(errors.length > 0)?_c('input-error-icon',{staticClass:"mr-4"}):_vm._e()]}}],null,false,10615583)}),_c('div',{staticClass:"\n                                absolute\n                                inset-y-0\n                                right-0\n                                flex\n                                items-center\n                                px-2\n                                text-gray-700\n                                pointer-events-none\n                            "},[_c('svg',{staticClass:"w-4 h-4 fill-current",attrs:{"viewBox":"0 0 20 20"}},[_c('path',{attrs:{"d":"M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"}})])])],1)]):_vm._e(),(_vm.needsValue)?_c('div',{staticClass:"w-full px-3 md:mb-0",class:{ 'md:w-1/2': _vm.needsValue }},[_c('label',{staticClass:"block mb-2 text-xs font-bold tracking-wide uppercase text-neutral-600",attrs:{"for":"dynamicValue"}},[_vm._v(" Value ")]),_c('div',{staticClass:"relative"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.dynamicValue.value),expression:"dynamicValue.value"}],staticClass:"block w-full px-2 py-1 text-sm form-input",class:{ 'border-red-700': errors.length > 0 },attrs:{"id":"dynamicValue","type":"number","min":"1"},domProps:{"value":(_vm.dynamicValue.value)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.dynamicValue, "value", $event.target.value)}}}),(errors.length > 0)?_c('input-error-icon'):_vm._e()]}}],null,false,1988105436)})],1)]):_vm._e()]):_vm._e()]),(_vm.showAdvancedSettings)?_c('div',{staticClass:"w-full md:mb-0"},[_c('label',{staticClass:"block mb-2 text-xs font-bold tracking-wide uppercase text-neutral-600",attrs:{"for":"dynamicValue"}},[_vm._v(" Advanced Settings ")]),_c('div',{staticClass:"relative flex flex-col space-y-1 text-neutral-700"},[_c('label',{staticClass:"flex flex-row items-center block"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.param.sensitive),expression:"param.sensitive"}],staticClass:"mr-2 leading-tight",attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.param.sensitive)?_vm._i(_vm.param.sensitive,null)>-1:(_vm.param.sensitive)},on:{"change":function($event){var $$a=_vm.param.sensitive,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.param, "sensitive", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.param, "sensitive", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.param, "sensitive", $$c)}}}}),_c('span',{staticClass:"text-sm"},[_vm._v(" Treat as a Sensitive Request Parameter ")])])])]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }