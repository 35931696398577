






















































































































































































































































































































































































































import { XIcon } from '@vue-hero-icons/outline';
import { Scrollbar, HtmlModal } from '@/app/components';
import { dateFormats, datetimeFormats, minimalTimezoneSet as timezoneSet, unitTransformations } from '@/app/constants';
import { computed, defineComponent, ref } from '@vue/composition-api';
import * as R from 'ramda';
import { TwSelect, JsonEditor } from '@/app/components';
import { Status } from '@/modules/data-model/constants';
export default defineComponent({
    name: 'MappingDetails',
    components: { Scrollbar, TwSelect, JsonEditor, HtmlModal, XIcon },
    model: {
        prop: 'selectedFields',
        event: 'change',
    },
    props: {
        selectedFields: {
            type: Array,
            required: true,
        },
        model: {
            type: Object,
            required: true,
        },
        parentConcept: {
            type: Object,
            required: false,
        },
        customizedConcepts: {
            type: Object,
            required: true,
        },
        countMultiple: {
            type: Number,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { emit }) {
        const sortByName = R.sortWith([R.ascend(R.prop('name'))]);
        const isEmpty = computed(() => props.selectedFields.length === 0);
        const isSingleSelection = computed(() => props.selectedFields.length === 1);
        const isSelectionValid = computed(() =>
            props.selectedFields.reduce(
                (result: boolean, field: any) => field.target.id === null && result,
                !!props.parentConcept,
            ),
        );
        const concept: any = computed(() => props.selectedFields[0]);
        const oneElementArraysOptions = computed(() => {
            return concept.value.target.path
                .filter((field: string) => field.includes('[]'))
                .map((field: string, i: number) => {
                    return { name: field, index: i };
                });
        });
        const showExampleStructureModal = ref<boolean>(false);
        const definition = computed(() => '');
        const referenceConcept = ref<any>(null);
        const referencePrefix = ref<string | null>(null);
        const userPrefixes = computed(() => {
            if (props.parentConcept && R.hasPath([props.parentConcept.id], props.customizedConcepts)) {
                return props.customizedConcepts[props.parentConcept.id];
            }

            return [];
        });
        const customPrefix = ref({ prefix: '', description: '' });
        const relatedConceptName = computed(() => {
            const referenceConceptName =
                referenceConcept.value.referenceConceptName.charAt(0).toUpperCase() +
                referenceConcept.value.referenceConceptName.slice(1);

            const prefixName = referencePrefix.value === 'custom' ? customPrefix.value.prefix : referencePrefix.value;

            if (referenceConcept.value.metadata && referenceConcept.value.metadata.multiple) {
                return `${prefixName}${referenceConceptName}[]`;
            }

            return `${prefixName}${referenceConceptName}`;
        });
        const referenceConceptDescription = ref<string | null>(null);

        const relatedConcepts = computed(() =>
            sortByName(
                props.parentConcept?.children.filter(
                    (obj: any) => obj.type === 'object' && obj.status === Status.Stable,
                ),
            ),
        );

        const oneElementArrays = computed(() => {
            if (concept.value) {
                return (
                    concept.value.target.path.filter((p: string) => p.includes('[]')).length -
                    concept.value.source.path.filter((p: string) => p.includes('[]')).length
                );
            }
            return 0;
        });

        const sampleValues: any = {
            string: 'sample',
            integer: 123,
            double: 123.4,
            number: 123.4,
            datetime: '2020-02-20 00:00:00',
            date: '2020-02-20',
            time: '00:00:00',
            boolean: true,
        };

        /**
         * Generate example structure of the field data after mapping
         *
         * For each array parent in target which has not been selected to be a one-element-array - add 2 children elements,
         * otherwise only 1, in order to create a structure that has multiple elements for each non-one-element array
         *
         * Ths makes use of Ramdas' assocPath: https://ramdajs.com/docs/#assocPath
         */
        const generateExampleStructure = computed(() => {
            if (oneElementArrays.value - concept.value.transformation.oneElementArrays.length === 0) {
                const assocPath: any[] = concept.value.target.path.flatMap((field: string) =>
                    field.includes('[]') ? [field.slice(0, field.length - 2), 0] : [field],
                );

                const actualArraysIndex = assocPath.reduce(
                    (prev, curr, index) => {
                        if (curr === 0) {
                            if (!concept.value.transformation.oneElementArrays.includes(prev[0])) {
                                return [prev[0] + 1, [...prev[1], index]];
                            } else {
                                return [prev[0] + 1, prev[1]];
                            }
                        } else {
                            return prev;
                        }
                    },
                    [0, []],
                )[1];

                const exampleValue = sampleValues[concept.value.target.type] || null;
                let exampleStructure = R.assocPath(assocPath, { [concept.value.target.title]: exampleValue }, {});
                const sourceArrays = concept.value.source.path.filter((p: string) => p.includes('[]')).length;
                for (let i = 0; i < sourceArrays; i++) {
                    assocPath[actualArraysIndex[i]] = 1;
                    exampleStructure = R.assocPath(
                        assocPath,
                        { [concept.value.target.title]: exampleValue },
                        exampleStructure,
                    );
                    assocPath[actualArraysIndex[i]] = 0;
                }
                return JSON.stringify(exampleStructure, null, '\t');
            }
            return null;
        });

        const setConcept = async (predict: boolean) => {
            emit('concept-changed');
            if (referencePrefix.value === 'custom' && props.parentConcept) {
                let customizedConcepts = R.clone(props.customizedConcepts);
                if (!R.hasPath([props.parentConcept.id], customizedConcepts)) {
                    customizedConcepts = R.assocPath([props.parentConcept.id], [], customizedConcepts);
                }
                customizedConcepts[props.parentConcept.id].push(R.clone(customPrefix.value));

                emit('customized-concepts-changed', customizedConcepts);
            }

            props.selectedFields.forEach((field: any) => {
                field.target.path.push(relatedConceptName.value);
                field.target.parentIds.push(referenceConcept.value.referenceConceptId);
                field.target.categories.push(referenceConcept.value.referenceConceptName);
            });

            referenceConcept.value = null;
            referencePrefix.value = null;
            customPrefix.value = { prefix: '', description: '' };

            if (predict) {
                emit('predict');
            }
        };

        const invalidPrefix = computed(() => {
            return (
                !referencePrefix.value ||
                (referencePrefix.value === 'custom' && customPrefix.value.prefix.trim().length === 0) ||
                (referencePrefix.value === 'custom' && customPrefix.value.description.trim().length === 0)
            );
        });

        const changedDateFormat = () => {
            if (
                concept.value &&
                concept.value.target &&
                concept.value.target.type === 'datetime' &&
                [
                    'ISO 8601',
                    'Unix Timestamp (nanoseconds)',
                    'Unix Timestamp (microseconds)',
                    'Unix Timestamp (milliseconds)',
                    'Unix Timestamp (seconds)',
                ].indexOf(concept.value.transformation.sourceDateFormat) > -1
            ) {
                concept.value.transformation.sourceTimezone = 'UTC';
            }
            emit('has-change');
        };

        const errors = computed(() => {
            if (concept.value?.temp?.invalid) {
                return {
                    oneElementArrays:
                        oneElementArrays.value - concept.value?.transformation?.oneElementArrays?.length > 0,
                    sourceUnit: !concept.value?.transformation?.sourceUnit,
                    sourceDateFormat: !concept.value?.transformation?.sourceDateFormat,
                    sourceTimezone: !concept.value?.transformation?.sourceTimezone,
                    order: !concept.value?.transformation?.order,
                };
            }
            return {};
        });

        return {
            concept,
            customPrefix,
            dateFormats,
            datetimeFormats,
            definition,
            invalidPrefix,
            isEmpty,
            isSelectionValid,
            isSingleSelection,
            timezoneSet,
            referenceConcept,
            referenceConceptDescription,
            referencePrefix,
            relatedConcepts,
            setConcept,
            unitTransformations,
            userPrefixes,
            emit,
            changedDateFormat,
            oneElementArrays,
            oneElementArraysOptions,
            generateExampleStructure,
            showExampleStructureModal,
            errors,
        };
    },
});
