


















































































































































































































































































































































import * as R from 'ramda';
import { defineComponent, computed, ref, provide, inject, watch } from '@vue/composition-api';
import { useAxios } from '@vue-composable/axios';
import TreeView from '@/app/components/treeview/TreeView.vue';
import { ButtonGroup, WaitModal, Scrollbar } from '@/app/components';
import { ErrorCodes } from '@/app/constants/error-codes';
import { useJsonObject } from '@/app/composable';
import { StatusCode } from '@/modules/data-checkin/constants';
import MappingDetails from './Details.vue';
import ConceptView from './ConceptView.vue';
import { FieldPrediction, FieldConfiguration } from './mapping.types';
import { ModelAPI } from '../../api';
import { useMapping, useModelSearch, MappingFilter, useSuggestions } from '../../composable';
import SuggestionModal from '../../components/SuggestionModal.vue';
import WizardActions from '../../components/WizardActions.vue';
import ConceptOverviewView from './ConceptOverviewView.vue';
import { renamings } from '@/app/utilities';

export default defineComponent({
    name: 'MappingConfiguration',
    model: {
        prop: 'configuration',
    },
    components: {
        ButtonGroup,
        ConceptView,
        MappingDetails,
        SuggestionModal,
        TreeView,
        WaitModal,
        Scrollbar,
        WizardActions,
        ConceptOverviewView,
    },
    props: {
        tabId: {
            type: Number,
            required: true,
        },
        configuration: {
            type: Object,
            required: true,
        },
        message: {
            type: Object,
            required: false,
        },
        stats: {
            type: Object,
            required: false,
        },
        model: {
            type: Object,
            required: true,
        },
        flatModel: {
            type: Array as any,
            required: true,
        },
        sample: {
            type: Array,
            required: true,
        },
        isFinalized: {
            type: Boolean,
            default: false,
        },
        hasChanges: {
            type: Boolean,
            required: true,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        isValid: {
            type: Boolean,
            default: true,
        },
        mappedConceptsExist: {
            type: Boolean,
            default: false,
        },
        canRestart: {
            type: Boolean,
            required: true,
        },
        mappingStatus: {
            type: String,
            default: 'configuration',
        },
    },
    setup(props, { emit, root }) {
        provide('dragging', ref(false));
        const activeTab = inject('activeTab');
        const runPrediction = ref(false);
        const activeFilter = ref<string>('all');
        const nodes = ref([props.model]);
        const selectedMappings = ref<any[]>([]);
        const selectedNode = ref(null);
        const dragging = ref(false);
        const selectNode = (node: any) => {
            selectedNode.value = node;
        };
        const dataModel = props.model;
        const { loading, exec, cancel } = useAxios();
        const domainName = computed(() => {
            if (props.configuration.domain?.name) {
                return `${props.configuration.domain.name
                    .charAt(0)
                    .toUpperCase()}${props.configuration.domain.name.substring(1)}`;
            }
            return 'None';
        });

        const statsInField = (fieldId: number) => {
            if (props.stats && fieldId.toString() in props.stats) {
                return props.stats[fieldId.toString()];
            }
            return null;
        };

        const failedStatsInField = (fieldId: any) => {
            if (props.message && props.message.stats) {
                return props.message.stats[fieldId.toString()];
            }
            return null;
        };

        const failedReasonsInField = (fieldId: any) => {
            if (
                props.message &&
                props.message.failedTransformations &&
                fieldId.toString() in props.message.failedTransformations
            ) {
                return props.message.failedTransformations[fieldId.toString()];
            }
            return null;
        };

        const categoryName = computed(() => {
            if (props.configuration.concept?.name) {
                return `${props.configuration.concept.name
                    .charAt(0)
                    .toUpperCase()}${props.configuration.concept.name.substring(1)}`;
            }
            return 'None';
        });

        const standardName = computed(() => {
            if (props.configuration.standard?.name) {
                return `${props.configuration.standard.name
                    .charAt(0)
                    .toUpperCase()}${props.configuration.standard.name.substring(1)}`;
            }
            return 'None';
        });

        const rootConcept = computed(
            () => dataModel.children.filter((obj: any) => obj.id === props.configuration.concept.id)[0],
        );

        const { getFixedJSON } = useJsonObject();

        const { createFieldConfiguration, extractFieldSample, initialize, getPredictionPayload } = useMapping(
            getFixedJSON(props.sample),
            rootConcept.value,
        );

        const selectedConceptId = computed<number | null>(() => {
            const selectedIds = R.uniq(R.map(R.view(R.lensPath(['target', 'parentIds', -1])), selectedMappings.value));
            if (selectedIds.length === 0) return props.configuration.concept.id;
            if (selectedIds.length === 1) return selectedIds[0];
            return null;
        });

        const displayModel = computed<any[]>(() => {
            return dataModel.children.filter((obj: any) => obj.id === selectedConceptId.value);
        });

        const { query, search, result, findById } = useModelSearch(displayModel, dataModel, {
            keys: [
                { name: 'name', weight: 2 },
                { name: 'relatedTerms', weight: 1 },
            ],
            minMatchCharLength: 3,
            shouldSort: true,
            threshold: 0.2,
        });
        const deprecatedFields = [];
        for (let f = 0; f < props.configuration.fields.length; f++) {
            const field = props.configuration.fields[f];
            if (!R.isNil(field.target.id)) {
                const conceptForField = findById(field.target.id);
                if (!conceptForField) {
                    deprecatedFields.push(field.target.id);
                }
            }
        }
        if (deprecatedFields.length > 0) {
            emit('deprecated-fields', deprecatedFields);
        }

        const selectedConcept = computed(() => displayModel.value?.[0]);

        if (props.configuration.fields.length === 0) {
            props.configuration.fields = initialize(); // eslint-disable-line no-param-reassign
            runPrediction.value = true;
        }

        const newSuggestion = ref<any>(null);
        const {
            showModal: showSuggestionModal,
            initConcept,
            domainConcepts,
            domainStandards,
        } = useSuggestions(props.model);

        const createSuggestion = () => {
            newSuggestion.value = initConcept();
            showSuggestionModal.value = true;
        };

        const saveSuggestion = () => {
            exec(ModelAPI.createSuggestion(newSuggestion.value))
                .then(() => {
                    showSuggestionModal.value = false;
                    (root as any).$toastr.s('Suggestion submitted successfuly');
                })
                .catch(() => {
                    (root as any).$toastr.e('Error submitting suggestion');
                });
        };

        const filterMapping = (option: string) => {
            activeFilter.value = option;
        };

        const setConcept = (field: any, concept: any, prediction: any = null) => {
            const idx = props.configuration.fields.indexOf(field);
            if (~idx) {
                props.configuration.fields.splice(idx, 1, createFieldConfiguration(field, concept, prediction));
                if (activeFilter.value !== MappingFilter.Unidentified && !prediction) {
                    selectedMappings.value = [props.configuration.fields[idx]];
                } else if (activeFilter.value === MappingFilter.Unidentified) {
                    const index = selectedMappings.value.indexOf(
                        selectedMappings.value.indexOf(props.configuration.fields[idx]),
                    );
                    if (index >= 0) {
                        selectedMappings.value.splice(index, 1);
                    }
                }
                emit('changed');
            }
        };

        const clearMapping = (field: any) => {
            field.target.id = null; // eslint-disable-line no-param-reassign
            field.target.title = null; // eslint-disable-line no-param-reassign
            field.target.type = null; // eslint-disable-line no-param-reassign
            field.temp.invalid = false; // eslint-disable-line no-param-reassign

            if (props.mappingStatus === StatusCode.Update) {
                emit('clear-mapping', field.source.id);
            } else {
                emit('changed');
            }
        };

        const filteredFields = computed(() => {
            if (props.isFinalized) {
                return props.configuration.fields.filter((obj: any) => {
                    return 'target' in obj && 'id' in obj.target && obj.target.id;
                });
            }
            return props.configuration.fields.filter((obj: any) => {
                switch (activeFilter.value) {
                    case MappingFilter.Predicted:
                        return obj.target.id && obj.prediction && obj.prediction.score;
                    case MappingFilter.Corrected:
                        return obj.temp && obj.temp.userDefined;
                    case MappingFilter.Unidentified:
                        return obj.target.id === null;
                    case MappingFilter.Invalid:
                        return obj.temp && obj.temp.invalid;
                    case MappingFilter.Selected:
                        return selectedMappings.value.includes(obj);
                    default:
                        return true;
                }
            });
        });

        const updateSelected = (value: any, multiple = false) => {
            const idx = selectedMappings.value.findIndex((obj) => obj === value);

            if (multiple) {
                if (~idx) {
                    selectedMappings.value.splice(idx, 1);
                } else {
                    selectedMappings.value.push(value);
                }
            } else {
                if (~idx && selectedMappings.value.length === 1) {
                    selectedMappings.value.splice(0);
                } else {
                    selectedMappings.value = [value];
                }
            }
        };

        const errorMessage = computed(
            () => ErrorCodes[props.message?.errorCode] || `Unknown Error Code: ${props.message?.errorCode}`,
        );

        const clearSelection = () => {
            selectedMappings.value.splice(0);
        };

        const handleEscape = (e: KeyboardEvent) => {
            if (e.key === 'Esc' || e.key === 'Escape') {
                clearSelection();
            }
        };

        const setCustomizedConcepts = (value: any) => {
            props.configuration.customizedConcepts = value; // eslint-disable-line no-param-reassign
        };

        const countMultiple = computed(() => {
            if (selectedMappings.value.length === 1) {
                const { id, path } = selectedMappings.value[0].target;
                return props.configuration.fields.reduce((count: number, field: any) => {
                    if (field.target.id === id && R.equals(field.target.path, path)) return count + 1;
                    return count;
                }, 0);
            }

            return 0;
        });

        document.addEventListener('keydown', handleEscape);
        root.$once('hook:beforeDestroy', () => {
            document.removeEventListener('keydown', handleEscape);
        });

        const selectedFields = computed(() => {
            const diff = R.difference(props.configuration.fields, selectedMappings.value);
            return R.pluck('source' as any, R.difference(props.configuration.fields, diff)).reduce(
                (fields: any[], obj: any) => {
                    fields.push({ ...obj, sample: extractFieldSample(obj.title, obj.path) });
                    return fields;
                },
                [],
            );
        });

        // Payload for prediction
        const conceptMetadata = (id: number): any | null => R.find(R.propEq('id', id), props.flatModel);

        const parsePrediction = (predictionResponse: any) => {
            for (let idx = 0; idx < props.configuration.fields.length; idx++) {
                const field: FieldConfiguration = props.configuration.fields[idx];
                const fieldPrediction: FieldPrediction | null = predictionResponse[field.source.id];

                if (fieldPrediction && fieldPrediction.matchings) {
                    setConcept(
                        props.configuration.fields[idx],
                        conceptMetadata(fieldPrediction.matchings.target),
                        fieldPrediction.matchings,
                    );
                }
            }
        };

        const predict = () => {
            const payload = getPredictionPayload(
                selectedFields.value.length > 0
                    ? selectedFields.value.map((obj: FieldConfiguration) => R.omit(['sample'], obj)) // If we have selected fields, use them
                    : props.configuration.fields.map((obj: FieldConfiguration) => obj.source), // If not, send the source information from all fields
                props.configuration.domain.id,
                props.configuration.standard,
                selectedConcept.value.id,
            );
            exec(ModelAPI.mappingPrediction(payload)).then((response: any) => {
                if (response && response.data) {
                    parsePrediction(response.data);
                    selectedMappings.value.splice(0);
                    emit('changed');
                }
            });
        };

        const validateMapping = () => {
            emit('validate');
            clearSelection();
            root.$nextTick(() => {
                if (!props.isValid) {
                    activeFilter.value = MappingFilter.Invalid;
                } else {
                    if (activeFilter.value === MappingFilter.Invalid) {
                        activeFilter.value = MappingFilter.All;
                    }
                    (root as any).$toastr.i('Mapping configuration is valid', 'Success');
                }
            });
        };

        // Get initial prediction
        if (runPrediction.value) {
            predict();
        }

        emit('changed');

        const hasChange = () => {
            emit('changed', selectedMappings.value[0].source.id);
        };

        const failedStepMessage = computed(() => (props.mappingStatus === StatusCode.Update ? props.message : null));

        watch(
            () => selectedConceptId.value,
            (newSelectedConceptId: any, oldSelectedConceptId: any) => {
                if (newSelectedConceptId !== oldSelectedConceptId) {
                    query.value = '';
                }
            },
        );

        return {
            renamings,
            activeTab,
            activeFilter,
            cancel,
            clearMapping,
            clearSelection,
            countMultiple,
            createSuggestion,
            dataModel,
            displayModel,
            domainConcepts,
            domainStandards,
            filteredFields,
            filterMapping,
            loading,
            newSuggestion,
            nodes,
            predict,
            query,
            result,
            rootConcept,
            saveSuggestion,
            search,
            selectedConcept,
            selectedConceptId,
            selectedFields,
            selectedMappings,
            selectedNode,
            selectNode,
            setConcept,
            setCustomizedConcepts,
            showSuggestionModal,
            updateSelected,
            validateMapping,
            domainName,
            categoryName,
            standardName,
            dragging,
            statsInField,
            failedStatsInField,
            failedReasonsInField,
            errorMessage,
            emit,
            hasChange,
            StatusCode,
            failedStepMessage,
        };
    },
});
