



































































































































































































import { defineComponent, ref, computed } from '@vue/composition-api';
import { ExclamationIcon } from '@vue-hero-icons/outline';
import { getTransformations, getTransformationText } from '../../composable';
import TransformationDetails from './TransformationDetails.vue';
import { FieldPath } from '../../components';

export default defineComponent({
    name: 'ConceptOverviewView',
    components: { TransformationDetails, FieldPath, ExclamationIcon },
    props: {
        concept: {
            type: Object,
            required: true,
        },
        isFinalized: {
            type: Boolean,
            default: false,
        },
        successfulTransformations: {
            type: Object,
            required: false,
            default: null,
        },
        failedTransformations: {
            type: Object,
            required: false,
            default: null,
        },
        failedTransformationReasons: {
            type: Array,
            required: false,
            default: null,
        },
        message: {
            type: Object,
            required: false,
        },
    },
    setup(props) {
        const expanded = ref<boolean>(false);
        const conceptClicked = () => {
            expanded.value = !expanded.value;
        };

        const trimPath = () => {
            props.concept.target.path.splice(-1, 1);
            props.concept.target.parentIds.splice(-1, 1);
        };

        const transformations = computed(() => {
            return getTransformations(props.concept);
        });

        const failedTransformationReason = computed(() => {
            if (
                props.message &&
                props.message.stats &&
                props.message.failedTransformations &&
                props.concept.source.id
            ) {
                let fieldId: any = null;
                Object.entries(props.message.stats).forEach((entry: any) => {
                    const [key, value]: any = entry;
                    if (props.concept.source.id === value.source_id) {
                        fieldId = key;
                    }
                });

                if (fieldId) {
                    return props.message.failedTransformations[fieldId];
                }
            }
            return null;
        });

        return {
            expanded,
            trimPath,
            conceptClicked,
            transformations,
            getTransformationText,
            failedTransformationReason,
        };
    },
});
