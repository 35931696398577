<template>
    <div class="flex flex-1 overflow-y-auto">
        <portal to="page-title">
            Data Storage for:
            <span v-if="!jobLoading" class="font-medium text-primary-700">{{ job.name }}</span>
        </portal>
        <portal to="page-status">
            <div
                v-if="step && step.status === StatusCode.Deprecated"
                v-tooltip="renamings(`The data checkin job is deprecated and cannot be updated`)"
                class="px-2 py-1 ml-2 text-sm text-red-700 uppercase bg-red-100 rounded"
            >
                {{ step.status }}
            </div>
        </portal>
        <portal to="loading">
            <orbit-spinner v-if="isLoading" :animation-duration="1000" :size="32" color="#5C69D1" />
        </portal>
        <portal to="page-actions">
            <wizard-actions
                back-route="data-checkin-jobs"
                :has-save="false"
                :has-changes="hasChanges"
                :can-finalize="canFinalize"
                :is-finalized="isFinalized"
                :can-restart="canRestart"
                :disabled="isLoading || loading"
                @restart="restartStep"
                @finalize="save"
            >
                <template slot="finalize">{{ renamings("Finalize Dataset") }}</template>
                <template v-if="restartStep" slot="restart-step">Restart</template>
            </wizard-actions>
        </portal>

        <div class="flex flex-col flex-grow">
            <div v-if="error" class="container px-1 mx-auto mt-8">
                <div class="p-4 text-red-700 bg-red-100 border-l-4 border-red-500 rounded-r shadow" role="alert">
                    <p class="font-bold">An error has occured!</p>
                    <p>{{ error.message }}</p>
                </div>
            </div>

            <div class="flex flex-1 overflow-y-auto">
                <div class="flex-1 mx-auto max-w-7xl sm:px-6 lg:px-8">
                    <div v-if="step" class="container px-1 mx-auto space-y-8">
                        <validation-observer ref="loaderRef">
                            <form-block
                                class="my-8"
                                title="Destination"
                                description="How do you want your data to be handled?"
                            >
                                <div>
                                    <div class="flex items-center">
                                        <input
                                            id="createOption"
                                            v-model="step.configuration.target"
                                            type="radio"
                                            class="text-primary-600 form-radio disabled:text-neutral-400 disabled:cursor-not-allowed"
                                            name="target"
                                            value="create"
                                            :disabled="isFinalized"
                                        />
                                        <label
                                            for="createOption"
                                            class="ml-3 text-sm font-bold tracking-wide uppercase text-neutral-700"
                                            >{{ renamings("New Dataset") }}</label
                                        >
                                    </div>
                                    <p class="block pl-3 ml-4 text-sm text-neutral-500">
                                        {{ renamings("Create a new dataset and load the processed data") }}
                                    </p>
                                </div>
                                <div v-if="isFeatureEnabled('loader.updateExisting')">
                                    <div class="flex items-center">
                                        <input
                                            id="updateOption"
                                            v-model="step.configuration.target"
                                            type="radio"
                                            class="text-primary-600 form-radio disabled:text-neutral-400 disabled:cursor-not-allowed"
                                            name="target"
                                            value="update"
                                            :disabled="isFinalized || true"
                                        />
                                        <label
                                            for="updateOption"
                                            class="ml-3 text-sm font-bold tracking-wide uppercase text-neutral-500"
                                            > {{ renamings("Update Existing Dataset (Coming Soon)")}}</label
                                        >
                                    </div>
                                    <p class="block pl-3 ml-4 text-sm text-neutral-500">
                                         {{ renamings("Append the processed data to an existing dataset") }}
                                    </p>
                                </div>
                            </form-block>

                            <form-block
                                v-if="step.configuration.target === 'create'"
                                :title="renamings('Dataset Information')"
                                description="Enter a title and a short description for your data asset. You will be able to change these once the data asset is created"
                            >
                                <label class="block">
                                    <validation-provider v-slot="{ errors }" name="Name" rules="required|max:50">
                                        <span
                                            class="text-xs font-bold tracking-wider uppercase text-neutral-700"
                                            :class="{ 'text-red-600': errors.length > 0 }"
                                            >Name</span
                                        >
                                        <input
                                            v-model.trim="newAsset.name"
                                            class="block w-full mt-1 text-sm form-input disabled:bg-neutral-200 disabled:cursor-not-allowed"
                                            :class="{ 'border-red-600': errors.length > 0 }"
                                            :placeholder="renamings('Enter dataset name')"
                                            :disabled="isFinalized"
                                            type="text"
                                        />
                                        <span v-if="errors.length > 0" class="text-sm text-red-600">{{
                                            errors[0]
                                        }}</span>
                                    </validation-provider>
                                </label>

                                <label class="block">
                                    <validation-provider
                                        v-slot="{ errors }"
                                        name="Description"
                                        rules="required|max:4096"
                                    >
                                        <span
                                            class="text-xs font-bold tracking-wider uppercase text-neutral-700"
                                            :class="{ 'text-red-600': errors.length > 0 }"
                                            >Description</span
                                        >
                                        <textarea
                                            v-model.trim="newAsset.description"
                                            class="block w-full mt-1 text-sm resize-none form-textarea disabled:bg-neutral-200 disabled:cursor-not-allowed"
                                            :class="{ 'border-red-600': errors.length > 0 }"
                                            rows="3"
                                            :placeholder="renamings('Enter a short description for your dataset')"
                                            :disabled="isFinalized"
                                        ></textarea>
                                        <span v-if="errors.length > 0" class="text-sm text-red-600">{{
                                            errors[0]
                                        }}</span>
                                    </validation-provider>
                                </label>
                            </form-block>

                            <form-block
                                v-if="step.configuration.target === 'update'"
                                :title="renamings('Dataset to Update')"
                                :description="renamings('Select the dataset you want to update')"
                            >
                                <div>
                                    <select class="w-full text-sm form-select" :disabled="isFinalized">
                                        <option :value="null" disabled>{{renamings("Select dataset to update")}}</option>
                                    </select>
                                </div>
                            </form-block>

                            <form-block
                                v-if="step.configuration.target === 'create' && isOnPremise"
                                class="my-8"
                                title="Output Information"
                                description="You can choose whether you want the output to be saved on the cloud or as a parquet file on-premise."
                            >
                                <div>
                                    <div class="flex items-center">
                                        <input
                                            id="cloud"
                                            v-model="step.configuration.location"
                                            type="radio"
                                            class="text-primary-600 form-radio disabled:text-neutral-400 disabled:cursor-not-allowed"
                                            value="cloud"
                                            :disabled="isFinalized"
                                        />

                                        <label
                                            for="cloud"
                                            class="ml-3 text-sm font-bold tracking-wide uppercase text-neutral-700"
                                        >
                                            Cloud
                                        </label>
                                    </div>
                                    <p class="block pl-3 ml-4 text-sm text-neutral-500">
                                        The output data will be saved on the cloud.
                                    </p>
                                </div>
                                <div>
                                    <div class="flex items-center">
                                        <input
                                            id="local"
                                            v-model="step.configuration.location"
                                            type="radio"
                                            class="text-primary-600 form-radio disabled:text-neutral-400 disabled:cursor-not-allowed"
                                            value="local"
                                            :disabled="isFinalized"
                                        />
                                        <label
                                            for="local"
                                            class="ml-3 text-sm font-bold tracking-wide uppercase text-neutral-700"
                                        >
                                            On-premise
                                        </label>
                                    </div>
                                    <p class="block pl-3 ml-4 text-sm text-neutral-500">
                                        The output data will be saved on-premise.
                                    </p>
                                    <div v-if="step.configuration.location === 'local'">
                                        <validation-provider v-slot="{ errors }" rules="required|path">
                                            <div class="flex flex-row pl-3 mt-2 ml-4">
                                                <span
                                                    class="text-xs font-bold tracking-wider uppercase text-neutral-700"
                                                    >Ouput path</span
                                                >
                                            </div>
                                            <div class="flex flex-row justify-between pl-3 ml-4">
                                                <input
                                                    v-model="step.configuration.outputPath"
                                                    name="path"
                                                    class="block w-full mt-1 text-sm form-input"
                                                    placeholder="Enter ouput path"
                                                    type="text"
                                                    :disabled="isFinalized"
                                                />
                                            </div>
                                            <span
                                                v-if="errors.length > 0"
                                                class="pl-3 ml-4 text-sm text-red-600 whitespace-pre-wrap"
                                            >
                                                {{ errors[0] }}
                                            </span>
                                        </validation-provider>
                                    </div>
                                </div>
                            </form-block>
                        </validation-observer>
                    </div>
                </div>
            </div>
        </div>
        <step-completion-modal v-if="showFinalizeModal" :job="job" :current-step="step" :next-step="nextStep" />
    </div>
</template>

<script>
import * as R from 'ramda';
import { defineComponent, computed, ref, inject } from '@vue/composition-api';
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { required, max } from 'vee-validate/dist/rules';
import { useAxios } from '@vue-composable/axios';
import { OrbitSpinner } from 'epic-spinners';
import { useQuery, useResult } from '@/app/composable';
import { FormBlock } from '@/app/components';
import { useErrors } from '@/app/composable/errors';
import { StatusCode } from '@/modules/data-checkin/constants';
import GET_JOB_WITH_STEPS from '../../graphql/getJobWithEnabledSteps.graphql';
import { JobsAPI, ModelAPI } from '../../api';
import { useStep } from '../../composable/steps';
import { AssetsAPI } from '../../../asset/api';
import { useJobConfiguration } from '../../composable/config-extraction';
import StepCompletionModal from '../../components/StepCompletionModal.vue';
import WizardActions from '../../components/WizardActions.vue';
import { renamings} from '@/app/utilities';
extend('required', {
    ...required,
    message: '{_field_} is required',
});

extend('max', {
    ...max,
    params: ['length'],
    message: '{_field_} cannot be more than {length} characters long',
});

extend('path', {
    message: (field) => {
        return `The specified ${field} is not a valid path \n\t    Sample path for linux: /home/my_output\n\t    Sample path for Windows: C:\\Documents\\my_output`;
    },
    validate: (value) => {
        const winPath = new RegExp('([a-zA-Z]:)?(\\\\[a-z  A-Z0-9_.-]+)+\\\\?');

        const linuxPath = new RegExp('^(/[^/]*)+/?$');

        return winPath.test(value) || linuxPath.test(value);
    },
});

export default defineComponent({
    name: 'Loader',
    props: {
        id: {
            type: [Number, String],
            required: true,
        },
    },
    components: { FormBlock, OrbitSpinner, ValidationProvider, ValidationObserver, WizardActions, StepCompletionModal },
    setup(props, { root }) {
        const isFeatureEnabled = inject('isEnabled');

        const loaderRef = ref(null);
        const jobId = parseInt(`${props.id}`, 10);
        const showFinalizeModal = ref(false);
        const nextStep = ref(null);
        // Fetch job information
        const { checkGQLAuthentication } = useErrors(root.$route);
        const { loading: jobLoading, error: jobError, result, onError } = useQuery(
            GET_JOB_WITH_STEPS,
            { id: jobId },
            { fetchPolicy: 'no-cache' },
        );
        onError(checkGQLAuthentication);
        const job = useResult(result, null, (data) => data.job);

        const isOnPremise = computed(() => {
            if (job.value) {
                return job.value.runnerId !== null;
            }
            return false;
        });

        const hasMapping = computed(() => {
            if (job.value) {
                const idx = job.value.dataCheckinJobSteps.findIndex(
                    (step) => step.dataCheckinStepType.name === 'mapping',
                );
                return idx >= 0;
            }

            return false;
        });

        const hasEncryption = computed(() => {
            if (job.value) {
                const idx = job.value.dataCheckinJobSteps.findIndex(
                    (step) => step.dataCheckinStepType.name === 'encryption',
                );
                return idx >= 0;
            }

            return false;
        });

        const hasCleaning = computed(() => {
            if (job.value) {
                const idx = job.value.dataCheckinJobSteps.findIndex(
                    (step) => step.dataCheckinStepType.name === 'cleaning',
                );
                return idx >= 0;
            }

            return false;
        });

        const hasAnonymisation = computed(() => {
            if (job.value) {
                const idx = job.value.dataCheckinJobSteps.findIndex(
                    (step) => step.dataCheckinStepType.name === 'anonymiser',
                );
                return idx >= 0;
            }
            return false;
        });

        // Fetch loader configuration
        const newAsset = ref({ name: '', description: null, assetTypeId: 1 }); // assetTypeId 1 should be Dataset
        const step = ref(null);

        const hasChanges = computed(() => {
            if (
                (!R.isNil(newAsset.value.name) && R.trim(newAsset.value.name) !== '') ||
                (!R.isNil(newAsset.value.description) && R.trim(newAsset.value.description) !== '')
            ) {
                return true;
            }
            return false;
        });

        const canFinalize = computed(() => {
            return (
                !R.isNil(newAsset.value.name) &&
                R.trim(newAsset.value.name) !== '' &&
                !R.isNil(newAsset.value.description) &&
                R.trim(newAsset.value.description) !== '' &&
                step.value.status !== StatusCode.Deprecated
            );
        });

        const { isConfigEmpty, isFinalized, getNextStep, checkStepRestartEligibility, canRestart } = useStep(step);
        const { loading, error, exec } = useAxios(true);

        exec(JobsAPI.getStep(jobId, 'loader')).then(async (res) => {
            const configuration = {
                ...res.data.configuration,
                database: null,
                collection: null,
                target: 'create',
                location: 'cloud',
                outputPath: null,
            };
            if (isConfigEmpty(res.data.configuration)) {
                step.value = { ...res.data, configuration };
            } else {
                const { data } = await exec(AssetsAPI.getAsset(res.data.configuration.collection));
                newAsset.value = data;
                step.value = { ...res.data };
            }
            checkStepRestartEligibility();
        });

        const isLoading = computed(() => loading.value || jobLoading.value);
        const cancel = () => root.$router.push({ name: 'data-checkin-jobs' });

        const { getStructure, getUnmappedStructure, getLoaderSchema } = useJobConfiguration(job);

        const getDroppedFieldIds = async () => {
            if (hasAnonymisation.value) {
                const { data: anonymisation } = await exec(JobsAPI.getStep(jobId, 'anonymiser'));
                if (!isConfigEmpty(anonymisation.configuration)) {
                    return R.pluck(
                        'id',
                        anonymisation.configuration.fields.filter(
                            (field) =>
                                field.anonymisationType === 'identifier' &&
                                field.options.anonymisationMethod === 'drop',
                        ),
                    );
                }
            }
            return [];
        };

        const createAsset = async () => {
            const dataset = {
                ...newAsset.value,
                standard: null,
                metadata: {
                    distribution: {
                        type: null,
                        format: null,
                        velocity: null,
                        accessibility: null,
                        accrualMethod: null,
                        accrualPeriodicity: null,
                    },
                    runnerId: job.value.runnerId,
                    path: step.value.configuration.location === 'local' ? step.value.configuration.outputPath : null,
                    filenames: [],
                },
                structure: null,
                volume: { value: 0, unit: '' },
                processingRules: {},
            };

            const { data: harvester } = await exec(JobsAPI.getStep(jobId, 'harvester'));

            if (hasMapping.value) {
                const { data: mapping } = await exec(JobsAPI.getStep(jobId, 'mapping'));
                const droppedFieldIds = await getDroppedFieldIds();
                if (!isConfigEmpty(mapping.configuration)) {
                    const conceptIds = {
                        conceptIds: mapping.configuration.fields
                            .flatMap((field) => [...field.target.parentIds, field.target.id])
                            .filter((fieldId) => fieldId !== null),
                    };
                    conceptIds.conceptIds.push(mapping.configuration.domain.id); // domain id
                    conceptIds.conceptIds.push(mapping.configuration.concept.id); // primary concept id
                    const { data: conceptUids } = await exec(ModelAPI.conceptsUids(conceptIds));
                    dataset.standard = mapping.configuration.standard;
                    dataset.structure = await getStructure(
                        harvester.configuration,
                        mapping.configuration,
                        conceptUids,
                        droppedFieldIds,
                    );
                    dataset.volume.unit = dataset.structure.type === 'json' ? 'records' : 'files';
                    dataset.processingRules.mappingRules = mapping.configuration.fields.filter((obj) => {
                        return 'target' in obj && 'id' in obj.target && obj.target.id;
                    });
                }
            } else {
                dataset.structure = await getUnmappedStructure(harvester.configuration);
            }

            if (hasCleaning.value) {
                const { data: cleaning } = await exec(JobsAPI.getStep(jobId, 'cleaning'));
                if (!isConfigEmpty(cleaning.configuration)) {
                    dataset.processingRules.cleaningRules = cleaning.configuration.fields;
                }
            }

            if (hasEncryption.value) {
                const { data: encryption } = await exec(JobsAPI.getStep(jobId, 'encryption'));
                if (!isConfigEmpty(encryption.configuration)) {
                    dataset.processingRules.encryptionRules = encryption.configuration.fields.map((field) => {
                        return {
                            id: field.id,
                            uid: field.uid,
                            index: field.index,
                            encrypt: true,
                        };
                    });
                }
            }

            switch (dataset.structure.type) {
                case 'json':
                    if (dataset.structure.dataType === 'textBinary') {
                        dataset.metadata.distribution.format = ['JSON and Binary'];
                        dataset.metadata.distribution.type = 'Text and Binary';
                    } else {
                        dataset.metadata.distribution.format = ['JSON'];
                        dataset.metadata.distribution.type = 'Text';
                    }
                    dataset.volume.unit = 'records';
                    break;
                case 'other':
                    dataset.volume.unit = 'files';
                    break;
                default:
                // do nothing
            }

            switch (dataset.structure.source) {
                case 'file':
                    dataset.metadata.distribution.accrualMethod = 'By uploading updated/revised file(s)';
                    if (dataset.structure.type === 'other' || !dataset.structure.domain) {
                        dataset.metadata.distribution.accessibility = ['As a downloadable file'];
                    } else {
                        dataset.metadata.distribution.accessibility = ['Through an API'];
                    }
                    dataset.metadata.distribution.velocity = 'Batch';
                    break;
                case 'api':
                case 'internalApi':
                    dataset.metadata.distribution.accrualMethod = 'Through an API';
                    dataset.metadata.distribution.accessibility = ['Through an API'];
                    switch (dataset.structure.periodicity) {
                        case 'Continuous':
                            dataset.metadata.distribution.velocity = 'Near Real-Time';
                            break;
                        case 'Provider-dependent':
                            dataset.metadata.distribution.velocity = 'Batch';
                            break;
                        default:
                            dataset.metadata.distribution.velocity = 'Periodical';
                    }
                    break;
                case 'mqtt':
                case 'kafka':
                case 'externalKafka':
                    dataset.metadata.distribution.accrualMethod = 'Through a data streaming mechanism';
                    dataset.metadata.distribution.accessibility = [
                        'Through an API',
                        'Through a data streaming mechanism',
                    ];
                    dataset.metadata.distribution.velocity = 'Real-Time';
                    break;
                default:
                // do nothing
            }

            dataset.metadata.distribution.accrualPeriodicity = dataset.structure.periodicity;

            return exec(AssetsAPI.createAsset(dataset));
        };
        const save = async () => {
            const valid = await loaderRef.value.validate();
            let assetId = null;
            if (valid && step.value.status !== StatusCode.Deprecated) {
                // Add correct database type
                if (hasMapping.value) step.value.configuration.database = process.env.VUE_APP_LOADER_DATABASE;

                if (step.value.configuration.target === 'create') {
                    try {
                        const { data: asset } = await createAsset();
                        assetId = asset.id;
                        step.value.configuration.collection = asset.id;
                        step.value.configuration.schema = getLoaderSchema(asset.structure);
                        step.value.configuration.hasEncryption = hasEncryption.value;
                    } catch (e) {
                        root.$toastr.e('Error creating dataset', 'Error');
                        return;
                    }

                    // Save configuration
                    await exec(
                        JobsAPI.updateStep(step.value.id, {
                            configuration: step.value.configuration,
                            serviceVersion: process.env.VUE_APP_LOADER_VERSION,
                        }),
                    );
                    await exec(JobsAPI.update(jobId, { id: job.value.id, name: job.value.name, assetId }));
                } else if (step.value.configuration.target === 'update') {
                    // TODO: Handle update
                }
                exec(JobsAPI.finalize(step.value.id))
                    .then(() => {
                        getNextStep().then((stepTypeResponse) => {
                            showFinalizeModal.value = true;
                            nextStep.value = stepTypeResponse;
                        });
                    })
                    .catch(() => {
                        root.$toastr.e('Error finalizing step', 'Error');
                    });
            }
        };

        const restartStep = async () => {
            await exec(JobsAPI.restartStep(step.value.id)).then(() => {
                exec(JobsAPI.finalize(step.value.id))
                    .then(() => {
                        root.$toastr.s(`Data Check-in Job "${job.value.name}" is now restarting.`, 'Success');
                        root.$router.push({ name: 'data-checkin-jobs' });
                    })
                    .catch(() => {
                        root.$toastr.e('Restarting of the Data Check-in Job failed', 'Failed');
                    });
            });
        };

        return {
            cancel,
            error,
            hasMapping,
            isFinalized,
            isLoading,
            job,
            jobError,
            jobLoading,
            loaderRef,
            loading,
            newAsset,
            save,
            step,
            hasChanges,
            canFinalize,
            nextStep,
            showFinalizeModal,
            StatusCode,
            isOnPremise,
            isFeatureEnabled,
            canRestart,
            restartStep,
            renamings,
        };
    },
});
</script>
