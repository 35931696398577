






































































































































































































































































































































































































































































































































import * as R from 'ramda';
import { ref, computed } from '@vue/composition-api';
import { ConfirmButton } from '@/app/components';
import { constraintOptions, outliersRuleOptions, conditionsOptions } from '../../../constants';
import { useCleaning } from '../../../composable';
import { renamings } from '@/app/utilities';
export default {
    name: 'EditConstraint',
    props: {
        availableConstraintOptions: {
            type: Array,
            required: true,
        },
        alreadyDefinedConstraintTypes: {
            type: Array,
            required: true,
        },
        constraint: {
            type: Object,
            required: true,
        },
        selectedFieldsTypes: {
            type: Array,
            required: true,
        },
        referencedFields: {
            type: Array,
            required: true,
        },
        mode: {
            type: String,
            default: null,
        },
    },
    components: {
        ConfirmButton,
    },
    setup(props: any, { emit }: { emit: any }) {
        const newConstraint = ref<any>(props.constraint);
        const isNew = ref<boolean>(!newConstraint.value.type);
        const newConstraintOptions = computed(() => {
            if (isNew.value) {
                // remove already defined constraints
                return props.availableConstraintOptions.filter(
                    (option: any) => !props.alreadyDefinedConstraintTypes.find((con: any) => con === option.id),
                );
            }
            return constraintOptions;
        });
        const newConditionsOptions = computed(() => {
            if (props.selectedFieldsTypes.includes('string') || props.selectedFieldsTypes.includes('boolean')) {
                // remove GREATER_THAN and SMALLER_THAN
                return conditionsOptions.slice(0, 2);
            }
            return conditionsOptions;
        });
        const canAddConstraint = computed(() => {
            switch (newConstraint.value.type) {
                case 'RANGE':
                    if (
                        newConstraint.value.details.from === null ||
                        newConstraint.value.details.from === '' ||
                        newConstraint.value.details.to === null ||
                        newConstraint.value.details.to === ''
                    ) {
                        return false;
                    }
                    break;
                case 'REGULAR_EXPRESSION':
                    if (
                        newConstraint.value.details.regularExpression === null ||
                        newConstraint.value.details.regularExpression === ''
                    ) {
                        return false;
                    }
                    break;
                case 'MANDATORY':
                case 'UNIQUE':
                    break;
                case 'SET_MEMBERSHIP':
                    if (newConstraint.value.details.codeList === null) {
                        return false;
                    }
                    break;
                case 'CROSS_FIELD':
                    if (
                        newConstraint.value.details.conditions.filter(
                            (condition: any, index: number) =>
                                condition.conditionalOperator === null ||
                                condition.field === null ||
                                (condition.logicalOperator === null && index !== 0),
                        ).length > 0
                    ) {
                        return false;
                    }
                    break;
                case 'FOREIGN_KEY':
                    if (newConstraint.value.details.field === null) {
                        return false;
                    }
                    break;
                default:
                    return false;
            }
            if (newConstraint.value.outliersRule.type) {
                if (
                    newConstraint.value.outliersRule.type === 'DEFAULT_VALUE' &&
                    (newConstraint.value.outliersRule.replaceValue === null ||
                        newConstraint.value.outliersRule.replaceValue === '')
                ) {
                    return false;
                }
                return !(
                    newConstraint.value.outliersRule.type === 'PREVIOUS_VALUE' &&
                    (newConstraint.value.outliersRule.secondaryRule.replaceValue === null ||
                        newConstraint.value.outliersRule.secondaryRule.replaceValue === '')
                );
            }
            return false;
        });

        const setDetails = (type: string) => {
            newConstraint.value.type = type;
            newConstraint.value.outliersRule = {
                type: null,
                replaceValue: null,
                secondaryRule: null,
            };
            switch (type) {
                case 'RANGE':
                    newConstraint.value.details = {
                        from: null,
                        to: null,
                    };
                    if (props.selectedFieldsTypes.includes('time')) {
                        newConstraint.value.details.from = new Date().setHours(0, 0, 0, 0);
                        newConstraint.value.details.to = new Date().setHours(0, 0, 0, 0);
                    }
                    break;
                case 'REGULAR_EXPRESSION':
                    newConstraint.value.details = {
                        regularExpression: null,
                    };
                    break;
                case 'SET_MEMBERSHIP':
                    newConstraint.value.details = {
                        codeList: null,
                    };
                    break;
                case 'CROSS_FIELD':
                    newConstraint.value.details = {
                        conditions: [
                            {
                                conditionalOperator: null,
                                field: null,
                                logicalOperator: null,
                            },
                        ],
                    };
                    break;
                case 'FOREIGN_KEY':
                    newConstraint.value.details = {
                        field: null,
                    };
                    break;
                default:
                    newConstraint.value.details = null;
            }
        };

        const setOutliersRuleOptions = (type: string) => {
            let options = outliersRuleOptions;

            switch (type) {
                case 'MANDATORY':
                case 'RANGE':
                case 'REGULAR_EXPRESSION':
                    // DROP, DEFAULT_VALUE, PREVIOUS_VALUE, MOST_FREQUENT, MEAN_VALUE, MEDIAN_VALUE, MIN_VALUE, MAX_VALUE
                    options = options.slice(0, 8);
                    break;
                case 'SET_MEMBERSHIP':
                    // DROP, DEFAULT_VALUE, PREVIOUS_VALUE, MOST_FREQUENT, MOST_SIMILAR
                    options = options.slice(0, 4).concat(options.slice(-1));
                    break;
                case 'UNIQUE':
                case 'CROSS_FIELD':
                    // DROP, DEFAULT_VALUE,
                    options = options.slice(0, 2);
                    break;
                case 'FOREIGN_KEY':
                    // DROP
                    options = options.slice(0, 1);
                    break;
                default:
                //
            }

            if (props.selectedFieldsTypes.length > 1) {
                return options.filter((option: any) => option.id === 'DROP');
            }
            if (props.mode === 'api' || props.mode === 'kafka' || props.mode === 'mqtt' || props.mode === 'internalApi') {
                return options.filter((option: any) => option.id === 'DROP' || option.id === 'DEFAULT_VALUE');
            }

            if (
                props.selectedFieldsTypes.includes('datetime') ||
                props.selectedFieldsTypes.includes('date') ||
                props.selectedFieldsTypes.includes('time')
            ) {
                return options.filter((option: any) => option.id !== 'MEAN_VALUE' && option.id !== 'MEDIAN_VALUE');
            }
            if (props.selectedFieldsTypes.includes('string') || props.selectedFieldsTypes.includes('boolean')) {
                return options.filter(
                    (option: any) =>
                        option.id !== 'MEAN_VALUE' &&
                        option.id !== 'MEDIAN_VALUE' &&
                        option.id !== 'MIN_VALUE' &&
                        option.id !== 'MAX_VALUE',
                );
            }
            return options;
        };

        const addCondition = () => {
            newConstraint.value.details.conditions.push({
                conditionalOperator: null,
                field: null,
                logicalOperator: null,
            });
        };

        const deleteCondition = (index: number) => {
            newConstraint.value.details.conditions.splice(index, 1);
        };

        const { getDatetimeText, setDateTime } = useCleaning(props.selectedFieldsTypes);

        const rangeError = computed(() => {
            const errorsArray: string[] = [];
            if (
                newConstraint.value.type === 'RANGE' &&
                newConstraint.value.details.from &&
                newConstraint.value.details.to
            ) {
                let { from } = newConstraint.value.details;
                let fromText = newConstraint.value.details.from;
                let { to } = newConstraint.value.details;
                let toText = newConstraint.value.details.to;
                // TODO: Check if the commented lines are necessary, or remove them completely
                // let { replaceValue } = newConstraint.value.outliersRule;
                // let secondaryRuleReplaceValue = newConstraint.value.outliersRule.secondaryRule
                //     ? newConstraint.value.outliersRule.secondaryRule.replaceValue
                //     : null;
                if (
                    props.selectedFieldsTypes.includes('datetime') ||
                    props.selectedFieldsTypes.includes('date') ||
                    props.selectedFieldsTypes.includes('time')
                ) {
                    from = new Date(from);
                    fromText = getDatetimeText(from);
                    to = new Date(to);
                    toText = getDatetimeText(to);
                    // replaceValue = replaceValue ? new Date(replaceValue) : null;
                    // secondaryRuleReplaceValue = secondaryRuleReplaceValue ? new Date(secondaryRuleReplaceValue) : null;
                } else if (
                    props.selectedFieldsTypes.includes('integer') ||
                    props.selectedFieldsTypes.includes('double') ||
                    props.selectedFieldsTypes.includes('float')
                ) {
                    from = Number(from);
                    to = Number(to);
                    // replaceValue = replaceValue ? Number(replaceValue) : null;
                    // secondaryRuleReplaceValue = secondaryRuleReplaceValue ? Number(secondaryRuleReplaceValue) : null;
                }

                if (from > to) {
                    errorsArray.push(`Invalid range <span class="font-bold">"${fromText} - ${toText}"</span>.`);
                }
            }
            return R.uniq(errorsArray);
        });

        const regexError = computed(() => {
            const errorsArray: string[] = [];
            if (newConstraint.value.type === 'REGULAR_EXPRESSION' && newConstraint.value.details.regularExpression) {
                try {
                    RegExp(newConstraint.value.details.regularExpression);
                } catch {
                    errorsArray.push(
                        `Invalid regular expression <span class="font-bold">"${newConstraint.value.details.regularExpression}"</span>.`,
                    );
                }
            }
            return R.uniq(errorsArray);
        });

        const crossFieldError = computed(() => {
            const errorsArray: string[] = [];
            if (
                newConstraint.value.type === 'CROSS_FIELD' &&
                newConstraint.value.details.conditions &&
                newConstraint.value.details.conditions.length > 0
            ) {
                const conditionFields: any = {};
                newConstraint.value.details.conditions.forEach((cond: any, index: number) => {
                    const conditionField = `${cond.conditionalOperator}-${cond.field}`;

                    if (conditionField in conditionFields) {
                        errorsArray.push(
                            `Condition <span class="font-bold">"${
                                conditionsOptions.find((con: any) => con.id === cond.conditionalOperator)?.label
                            }"</span> is defined for the field <span class="font-bold">"${cond.field.replace(
                                /__/g,
                                ' > ',
                            )}"</span> more than once.`,
                        );
                    } else {
                        conditionFields[conditionField] = {
                            logicalOperator: cond.logicalOperator,
                            index,
                        };
                    }
                });
                props.referencedFields.forEach((field: any) => {
                    const error = `Conflicting conditions <span class="font-bold">"{}"</span> and <span class="font-bold">"{}"</span> are defined for the same field <span class="font-bold">"${field.name.replace(
                        /__/g,
                        ' > ',
                    )}"</span>.`;
                    if (`EQUAL-${field.name}` in conditionFields && `NOT_EQUALS-${field.name}` in conditionFields) {
                        const idx =
                            conditionFields[`EQUAL-${field.name}`].index -
                            conditionFields[`NOT_EQUALS-${field.name}`].index;
                        if (idx === -1) {
                            let e = error.replace('{}', 'is equal to');
                            e = e.replace('{}', 'is not equal to');
                            errorsArray.push(e);
                        } else if (idx === 1) {
                            let e = error.replace('{}', 'is not equal to');
                            e = e.replace('{}', 'is equal to');
                            errorsArray.push(e);
                        }
                    }
                    if (
                        `EQUAL-${field.name}` in conditionFields &&
                        `SMALLER_THAN-${field.name}` in conditionFields &&
                        (conditionFields[`EQUAL-${field.name}`].logicalOperator === 'AND' ||
                            conditionFields[`SMALLER_THAN-${field.name}`].logicalOperator === 'AND')
                    ) {
                        const idx =
                            conditionFields[`EQUAL-${field.name}`].index -
                            conditionFields[`SMALLER_THAN-${field.name}`].index;
                        if (idx === -1) {
                            let e = error.replace('{}', 'is equal to');
                            e = e.replace('{}', 'is smaller than');
                            errorsArray.push(e);
                        } else if (idx === 1) {
                            let e = error.replace('{}', 'is smaller than');
                            e = e.replace('{}', 'is equal to');
                            errorsArray.push(e);
                        }
                    }
                    if (
                        `EQUAL-${field.name}` in conditionFields &&
                        `GREATER_THAN-${field.name}` in conditionFields &&
                        (conditionFields[`EQUAL-${field.name}`].logicalOperator === 'AND' ||
                            conditionFields[`GREATER_THAN-${field.name}`].logicalOperator === 'AND')
                    ) {
                        const idx =
                            conditionFields[`EQUAL-${field.name}`].index -
                            conditionFields[`GREATER_THAN-${field.name}`].index;
                        if (idx === -1) {
                            let e = error.replace('{}', 'is equal to');
                            e = e.replace('{}', 'is greater than');
                            errorsArray.push(e);
                        } else if (idx === 1) {
                            let e = error.replace('{}', 'is greater than');
                            e = e.replace('{}', 'is equal to');
                            errorsArray.push(e);
                        }
                    }
                    if (
                        `SMALLER_THAN-${field.name}` in conditionFields &&
                        `GREATER_THAN-${field.name}` in conditionFields &&
                        (conditionFields[`SMALLER_THAN-${field.name}`].logicalOperator === 'AND' ||
                            conditionFields[`GREATER_THAN-${field.name}`].logicalOperator === 'AND')
                    ) {
                        const idx =
                            conditionFields[`SMALLER_THAN-${field.name}`].index -
                            conditionFields[`GREATER_THAN-${field.name}`].index;
                        if (idx === -1) {
                            let e = error.replace('{}', 'is smaller than');
                            e = e.replace('{}', 'is greater than');
                            errorsArray.push(e);
                        } else if (idx === 1) {
                            let e = error.replace('{}', 'is greater than');
                            e = e.replace('{}', 'is smaller than');
                            errorsArray.push(e);
                        }
                    }
                    if (
                        `EQUAL-${field.name}` in conditionFields &&
                        `SMALLER_THAN-${field.name}` in conditionFields &&
                        `GREATER_THAN-${field.name}` in conditionFields &&
                        ((conditionFields[`EQUAL-${field.name}`].logicalOperator === null &&
                            conditionFields[`SMALLER_THAN-${field.name}`].logicalOperator === 'OR' &&
                            conditionFields[`GREATER_THAN-${field.name}`].logicalOperator === 'OR') ||
                            (conditionFields[`EQUAL-${field.name}`].logicalOperator === 'OR' &&
                                conditionFields[`SMALLER_THAN-${field.name}`].logicalOperator === null &&
                                conditionFields[`GREATER_THAN-${field.name}`].logicalOperator === 'OR') ||
                            (conditionFields[`EQUAL-${field.name}`].logicalOperator === 'OR' &&
                                conditionFields[`SMALLER_THAN-${field.name}`].logicalOperator === 'OR' &&
                                conditionFields[`GREATER_THAN-${field.name}`].logicalOperator === null))
                    ) {
                        let e = 'Condition <span class="font-bold">"{}"</span> is always true.';
                        const idx1 =
                            conditionFields[`EQUAL-${field.name}`].index -
                            conditionFields[`SMALLER_THAN-${field.name}`].index;
                        const idx2 =
                            conditionFields[`SMALLER_THAN-${field.name}`].index -
                            conditionFields[`GREATER_THAN-${field.name}`].index;
                        if (idx1 === -1 && idx2 === -1) {
                            e = e.replace('{}', 'is equal to OR is smaller than OR is greater than');
                            errorsArray.push(e);
                        } else if (idx1 === -2 && idx2 === 1) {
                            e = e.replace('{}', 'is equal to OR is greater than OR is smaller than');
                            errorsArray.push(e);
                        } else if (idx1 === 1 && idx2 === -2) {
                            e = e.replace('{}', 'is smaller than OR is equal to OR is greater than');
                            errorsArray.push(e);
                        } else if (idx1 === 2 && idx2 === -1) {
                            e = e.replace('{}', 'is smaller than OR is greater than OR is equal to');
                            errorsArray.push(e);
                        } else if (idx1 === -1 && idx2 === 2) {
                            e = e.replace('{}', 'is greater than OR is equal to OR is is smaller than');
                            errorsArray.push(e);
                        } else if (idx1 === 1 && idx2 === 1) {
                            e = e.replace('{}', 'is greater than OR is smaller than OR is equal to');
                            errorsArray.push(e);
                        }
                    }
                });
            }
            return R.uniq(errorsArray);
        });

        const errors = computed(() => {
            return rangeError.value.concat(regexError.value).concat(crossFieldError.value);
        });

        const saveConstraint = () => {
            if (
                props.selectedFieldsTypes.includes('datetime') ||
                props.selectedFieldsTypes.includes('date') ||
                props.selectedFieldsTypes.includes('time')
            ) {
                if (newConstraint.value.type === 'RANGE') {
                    if (newConstraint.value.details.from) {
                        newConstraint.value.details.from = setDateTime(newConstraint.value.details.from);
                    }
                    if (newConstraint.value.details.to) {
                        newConstraint.value.details.to = setDateTime(newConstraint.value.details.to);
                    }
                }
                if (newConstraint.value.outliersRule.replaceValue) {
                    newConstraint.value.outliersRule.replaceValue = setDateTime(
                        newConstraint.value.outliersRule.replaceValue,
                    );
                }
            }
            if (
                newConstraint.value.type === 'RANGE' &&
                (props.selectedFieldsTypes.includes('integer') ||
                    props.selectedFieldsTypes.includes('double') ||
                    props.selectedFieldsTypes.includes('float'))
            ) {
                if (newConstraint.value.details.from) {
                    newConstraint.value.details.from = Number(newConstraint.value.details.from);
                }
                if (newConstraint.value.details.to) {
                    newConstraint.value.details.to = Number(newConstraint.value.details.to);
                }
                if (newConstraint.value.outliersRule.replaceValue) {
                    newConstraint.value.outliersRule.replaceValue = Number(
                        newConstraint.value.outliersRule.replaceValue,
                    );
                }
            }
            if (isNew.value) {
                emit('save-create');
            } else {
                emit('save-update');
            }
        };

        const setOutliersRule = (type: string) => {
            newConstraint.value.outliersRule = {
                type,
                replaceValue: null,
                secondaryRule: null,
            };
            if (type === 'DEFAULT_VALUE' && props.selectedFieldsTypes.includes('time')) {
                newConstraint.value.outliersRule.replaceValue = new Date().setHours(0, 0, 0, 0);
            }
            if (type === 'PREVIOUS_VALUE') {
                newConstraint.value.outliersRule.secondaryRule = {
                    type: 'DEFAULT_VALUE',
                    replaceValue: null,
                    secondaryRule: null,
                };
            }
        };

        if (isNew.value) {
            emit('change-edit-mode', 'creating');
        } else {
            emit('change-edit-mode', 'updating');
        }

        if (!isNew.value) {
            if (props.selectedFieldsTypes.includes('time')) {
                if (newConstraint.value.type === 'RANGE') {
                    if (newConstraint.value.details.from) {
                        newConstraint.value.details.from = new Date(
                            new Date().setHours(
                                parseInt(newConstraint.value.details.from.substring(0, 2), 10),
                                parseInt(newConstraint.value.details.from.substring(3, 5), 10),
                                0,
                                0,
                            ),
                        );
                    }
                    if (newConstraint.value.details.to) {
                        newConstraint.value.details.to = new Date(
                            new Date().setHours(
                                parseInt(newConstraint.value.details.to.substring(0, 2), 10),
                                parseInt(newConstraint.value.details.to.substring(3, 5), 10),
                                0,
                                0,
                            ),
                        );
                    }
                }
                if (newConstraint.value.outliersRule.replaceValue) {
                    newConstraint.value.outliersRule.replaceValue = new Date(
                        new Date().setHours(
                            parseInt(newConstraint.value.outliersRule.replaceValue.substring(0, 2), 10),
                            parseInt(newConstraint.value.outliersRule.replaceValue.substring(3, 5), 10),
                            0,
                            0,
                        ),
                    );
                }
            }
            if (props.selectedFieldsTypes.includes('datetime')) {
                if (newConstraint.value.type === 'RANGE') {
                    if (newConstraint.value.details.from) {
                        newConstraint.value.details.from = new Date(newConstraint.value.details.from);
                    }
                    if (newConstraint.value.details.to) {
                        newConstraint.value.details.to = new Date(newConstraint.value.details.to);
                    }
                }
                if (newConstraint.value.outliersRule.replaceValue) {
                    newConstraint.value.outliersRule.replaceValue = new Date(
                        newConstraint.value.outliersRule.replaceValue,
                    );
                }
            }
        }

        return {
            emit,
            outliersRuleOptions,
            setDetails,
            setOutliersRuleOptions,
            conditionsOptions,
            isNew,
            addCondition,
            canAddConstraint,
            deleteCondition,
            constraintOptions,
            newConstraintOptions,
            newConditionsOptions,
            errors,
            saveConstraint,
            setOutliersRule,
            newConstraint,
            renamings,
        };
    },
};
