

































































































































































































import * as R from 'ramda';
import { defineComponent, ref, computed, onMounted, inject } from '@vue/composition-api';
import { OrbitSpinner } from 'epic-spinners';
import { useAxios } from '@vue-composable/axios';
import { useQuery, useResult, useErrors, useJsonObject } from '@/app/composable';
import { TwButton, WizardTabs, ConfirmModal } from '@/app/components';
import { StatusCode } from '@/modules/data-checkin/constants';
import CleaningConfiguration from './CleaningConfiguration.vue';
import CleaningReview from './CleaningReview.vue';
import GET_JOB from '../../graphql/getJob.graphql';
import { CleaningConfig } from './cleaning.types';
import { useStep, useMapping } from '../../composable';
import { JobsAPI } from '../../api';
import StepCompletionModal from '../../components/StepCompletionModal.vue';

export default defineComponent({
    name: 'Cleaning',
    props: {
        id: {
            type: [Number, String],
            required: true,
        },
    },
    components: {
        CleaningConfiguration,
        CleaningReview,
        OrbitSpinner,
        TwButton,
        WizardTabs,
        StepCompletionModal,
        ConfirmModal,
    },
    setup(props, { root }) {
        const renamings = inject("renamings");
        const { loading, error, exec } = useAxios(true);
        const jobId = parseInt(`${props.id}`, 10);
        const steps = ref([{ title: 'Configuration' }, { title: 'Review and Report' }]);
        const cleaningRef = ref<HTMLElement | null>(null);
        const message = ref<any>(null);
        const stats = ref<any>(null);
        const activeTab = ref(0);
        const hasChanges = ref<boolean>(false);
        const currentCleaningConfiguration = ref<CleaningConfig | any>(null);
        const editMode = ref<string | null>(null);
        const showFinalizeModal = ref<boolean>(false);
        const restartedStep = ref<boolean>(false);
        const nextStep = ref<any>(null);
        const showConfirmModal = ref<boolean>(false);
        const mode = ref<string | null>(null);
        const isMacOS = window.navigator.userAgent.indexOf('Mac OS') !== -1;
        const { extractMappingFieldNames } = useMapping([], null);
        const showOrderInformation = ref<boolean>(true);
        const loadingFinalization = ref<boolean>(false);

        // Fetch job information
        const { checkGQLAuthentication } = useErrors(root.$route);
        const { loading: jobLoading, error: jobError, result, onError, refetch } = useQuery(
            GET_JOB,
            {
                id: jobId,
            },
            { fetchPolicy: 'no-cache' },
        );
        onError(checkGQLAuthentication);
        const job = useResult(result, null, (data: any) => data.job);

        // Fetch cleaning configuration
        const cleaning = ref<any>(null);
        const {
            isConfigEmpty,
            isFinalized,
            getNextStep,
            updateAssetAfterFailedStep,
            checkStepRestartEligibility,
            canRestart,
            setupUpdatedConfiguration,
        } = useStep(cleaning, job);

        // Default (empty) configuration
        const configuration = ref<CleaningConfig | null>(null);

        exec(JobsAPI.getStep(jobId, 'harvester')).then((res: any) => {
            if (res && res.data && res.data.configuration) {
                mode.value = res.data.configuration.source;
            }
        });

        const { getFixedJSON } = useJsonObject();

        const fixedSample = computed(() => {
            if (job.value && job.value.sample) {
                return getFixedJSON(job.value.sample);
            }
            return [];
        });

        exec(JobsAPI.getStep(jobId, 'cleaning')).then((resCleaning: any) => {
            cleaning.value = resCleaning.data;
            if (cleaning.value.message) {
                message.value = cleaning.value.message;
            }
            if (cleaning.value.stats) {
                stats.value = cleaning.value.stats;
            }
            exec(JobsAPI.getStep(jobId, 'mapping')).then(async (mapping: any) => {
                if (!isConfigEmpty(mapping.data.configuration)) {
                    if (isConfigEmpty(resCleaning.data.configuration)) {
                        const conf: any = { fields: [] };
                        const mappingFields = extractMappingFieldNames(mapping.data.configuration.fields);
                        mappingFields.forEach((field: any) => {
                            const obj = R.clone(field);
                            obj.constraints = [];
                            conf.fields.push(obj);
                        });
                        configuration.value = R.clone(conf);
                    } else {
                        configuration.value = R.clone(resCleaning.data.configuration);
                        if (cleaning.value.status === StatusCode.Update) {
                            /**
                             * check if any fields have been added/ removed/ modified after
                             * revised mapping and update the cleaning configuration
                             */
                            configuration.value = await setupUpdatedConfiguration(
                                mapping.data.configuration.fields,
                                configuration.value,
                            );
                            currentCleaningConfiguration.value = R.clone(configuration.value);
                        }
                    }
                }
                checkStepRestartEligibility();
                refetch();
            });
        });

        const next = () => {
            if (editMode.value) {
                showConfirmModal.value = true;
                return;
            }
            activeTab.value += 1;
        };
        const previous = () => {
            activeTab.value -= 1;
        };

        const save = async () => {
            try {
                await exec(
                    JobsAPI.updateStep(cleaning.value.id, {
                        configuration: configuration.value,
                        serviceVersion: process.env.VUE_APP_CLEANER_VERSION,
                    }),
                );
                (root as any).$toastr.s('Cleaning configuration saved successfuly', 'Success');
                hasChanges.value = false;
            } catch (e) {
                (root as any).$toastr.e('Saving cleaning configuration failed', 'Failed');
                hasChanges.value = true;
            }
        };

        const proceed = () => {
            editMode.value = null;
            showConfirmModal.value = false;
            next();
        };

        const finalize = () => {
            loadingFinalization.value = true;
            exec(
                JobsAPI.updateStep(cleaning.value.id, {
                    configuration: configuration.value,
                    serviceVersion: process.env.VUE_APP_CLEANER_VERSION,
                }),
            ).then(() => {
                getNextStep().then(async (stepTypeResponse: any) => {
                    nextStep.value = stepTypeResponse;

                    /**
                     * If loader step (order = 100) has a different status than "configuration",
                     * it means that the Asset has already been created
                     */
                    if (
                        cleaning.value.status === StatusCode.Update &&
                        nextStep.value.order === 100 &&
                        nextStep.value.status !== StatusCode.Configuration
                    ) {
                        refetch(); // refetch job with its steps after the cleaning step is updated
                        if (job.value?.asset && job.value.asset.id) {
                            await updateAssetAfterFailedStep(job.value);
                            await exec(JobsAPI.finalize(cleaning.value.id));
                            restartedStep.value = true;
                        } else {
                            (root as any).$toastr.e(
                                'Failed finalizing revised Cleaning step due to an error',
                                'Failed',
                            );
                        }
                    } else {
                        await exec(JobsAPI.finalize(cleaning.value.id));
                        showFinalizeModal.value = true;
                    }
                    loadingFinalization.value = true;
                });
            });
        };

        const cancel = () => {
            root.$router.push({ name: 'data-checkin-jobs' });
        };

        onMounted(async () => {
            if (!isConfigEmpty(cleaning.value)) await next();
        });

        const restartStep = async () => {
            try {
                await exec(JobsAPI.restartStep(cleaning.value.id)).then((res: any) => {
                    cleaning.value = res.data;
                    currentCleaningConfiguration.value = cleaning.value.configuration;
                });
                (root as any).$toastr.s(
                    'The configuration of the cleaning step is now available for updates.',
                    'Success',
                );
                activeTab.value = 0;
            } catch (e) {
                (root as any).$toastr.e('Revising of the configuration of the cleaning step failed', 'Failed');
            }
        };

        const hasDifferenceInConfiguration = computed(() => {
            if (
                cleaning.value &&
                cleaning.value.status === StatusCode.Update &&
                message.value &&
                currentCleaningConfiguration.value
            ) {
                return JSON.stringify(currentCleaningConfiguration.value) !== JSON.stringify(configuration.value);
            }

            return true;
        });

        const stepStatus = computed(() =>
            cleaning.value && cleaning.value.status ? cleaning.value.status : StatusCode.Configuration,
        );

        const pageLoading = computed(() => {
            return loadingFinalization.value || loading.value || jobLoading.value;
        });

        return {
            renamings,
            activeTab,
            cancel,
            configuration,
            error,
            finalize,
            hasChanges,
            editMode,
            isFinalized,
            job,
            jobError,
            jobLoading,
            loading,
            cleaning,
            cleaningRef,
            next,
            previous,
            save,
            steps,
            showFinalizeModal,
            nextStep,
            fixedSample,
            mode,
            message,
            stats,
            isMacOS,
            proceed,
            showConfirmModal,
            StatusCode,
            showOrderInformation,
            canRestart,
            restartStep,
            hasDifferenceInConfiguration,
            restartedStep,
            pageLoading,
            stepStatus,
        };
    },
});
